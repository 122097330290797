import { useState, useEffect, useRef } from 'react'
import Page from '../components/layouts/Page'
import Table from '../components/Table'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import { getNotificationData } from '../funcs/firebase'
import ViewNotifStats from '../components/slideUps/viewNotifStats'

export default function NotificationsStats({ setOverlay, blur }) {

    // Table data
    
    const [notis, setNotis] = useState([])
    const [headers, setHeaders] = useState([])

    const loadNotis = async () => {
        const notifSnap = await getNotificationData();
        let notifData = [];
        var counter = notifSnap.docs.length; // to execute once all foreach execute async
        notifSnap.forEach(async (doc) => {
            const data = await doc.data();
            // make date from timestamp to human readable
            notifData.push({...data, date: new Date(data.date).toDateString(), sent_time: new Date(data.sent_time).toLocaleTimeString()});
            counter--;
            if (counter === 0) {
                //notifData.sort((notif1, notif2) => new Date(notif2.date) - new Date(notif1.date))
                //notifData = notifData.slice(0, 100);

                let headData = []
    
                if (!isMobile()) {
                    headData = [
                        { title: 'Title', field: 'title'},
                        { title: 'Message', field: 'message'},
                        { title: 'Date', field: 'date'},
                        { title: 'Ping Category', field: 'ping_category'},
                        { title: 'Sent time', field: 'sent_time'},
                        { title: 'Clicks', field: 'clickCount'},
                    ]
                }
                else {
                    headData = [
                        { title: 'Title', field: 'title'},
                        { title: 'clicks', field: 'clickCount'},
                    ]
                }
    

                setHeaders(headData)
                setNotis(notifData)
            }            
        })
       
    }

    const crudFunc = {
        update: (record) =>  {
            setOverlay(2, <ViewNotifStats setOverlay={ setOverlay } notif={record}/>)
        },
    }

    useEffect(() => {
        loadNotis()
    }, [])
   

    return (
        <Page
            title='Notification Stats'
            current={3}   
            blur={blur}   
            setOverlay={setOverlay} >
            <Table
                crudFunc={ crudFunc }
                setOverlay={setOverlay}
                headers={ headers }
                data={ notis } />
        </Page>
    )
}