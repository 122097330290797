import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/layouts/Page'
import ViewGuide from '../components/slideUps/viewGuide'
import StatCount from '../components/StatCount'
import { api } from '../funcs/apiCall'
import styles from '../styles/newsfeed.module.scss'
import rowStyles from '../styles/appfeed.module.scss'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import UpdateGuide from '../components/slideUps/updateGuide'
import { actions } from '../redux/actions'
import Modal from '../components/Modal'
import Table from '../components/Table'
import { rgx } from '../constants'
import datetime from '../lib/time'
import { crud, CrudControl } from '../lib/crud'
import { pinger } from '../components/slideUps/pinger'

export default function Guides({ setOverlay, blur }) {

    const dispatch = useDispatch()
    const token = useRef( useSelector( state => state.user.token )  ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const refresh = useSelector( state => state.refresh.run )
    const refreshPage = useSelector( state => state.refresh.page )

    const [alert, setAlert] = useState(false)
    const [searchStr, setSearchStr] = useState('')
    
    // Table data

    const [guides, setGuides] = useState([])
    const [headers, setHeaders] = useState([])

    // Field data

    const [ title, setTitle ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ url, setURL ] = useState('')
    const [ imgURL, setImgURL ] = useState('')
    const [ desc, setDesc ] = useState('')

    const fields = [
        {
            title: 'Title',
            pattern: /^(.+)$/g,
            value: title,
            canBlank: false,
            set: setTitle,
            icon: ''
        },
        {
            title: 'Image URL',
            pattern: rgx.url,
            value: imgURL,
            canBlank: false,
            set: setImgURL,
            icon: '/imgs/icons/img.png'
        },
        {
            title: 'Article',
            pattern: rgx.article,
            value: desc,
            canBlank: false,
            set: setDesc,
            icon: '/imgs/icons/pencil.png',
            big: true
        },
    ]

    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current

    const loadGuides = async () => {

        await api.get2('guide_news_history', { jwttoken: token }).then((res) => {

            let headData = []
            
            if (!isMobile()) {
                headData = [
                    { title: 'Image', field: 'image'},
                    { title: 'Title', field: 'title'},
                    { title: 'Category',  field: 'category'},
                    { title: 'Date', field: 'date'},
                    { title: 'Time', field: 'time'},
                    { title: 'Publisher', field: 'publisher'},
                ]
            }
            else {
                headData = [
                    { title: 'Image', field: 'image'},
                    { title: 'Title', field: 'title'},
                ]
            }

            let parsed = res.data.map((record) => {

                let created = datetime.getStandard(record.upload_time)
                
                return {
                    id: record.id,
                    image: record.image_url,
                    category: record.category,
                    date: created.date,
                    time: created.time,
                    title: record.title,
                    publisher: record.uploaded_by ? record.uploaded_by : 'Null',
                    article: record.article
                }
            })

            setHeaders(headData)
            setGuides(parsed)
        }).catch((e) => {
            console.log('ERROR: ', e)
            setGuides([])
        })
    }

    const guideProps = {
        "title": title,
        "image_url": imgURL,
        "article": desc,
        "category": category,
        "user_id": userID
    }
    
    const crudFunc = {
        create: <pinger.guide setOverlay={ setOverlay } load={ loadGuides } />,
        read: (record) => window.open(record.url, '_blank'),
        update: (record) =>  {
            setOverlay(2, <UpdateGuide setOverlay={ setOverlay } item={ guides.filter((item) => item.id == record.id)[0] }/>)
        },
        delete: async (itemList, callBack = () => {}) => crudControl.deleteGuide(itemList, loadGuides),
    }

    useEffect(() => {
        loadGuides()
    }, [])

    useEffect(() => {
        loadGuides()

        if ((refreshPage == 'guides') && refresh) {
            loadGuides()
            dispatch(actions.setRefresh(false))
        }
    }, [ refresh ])

    return (
        <>
            <Page
                title='Guides &amp; News'
                current={2}
                setOverlay={setOverlay}
                blur={blur}
                setSearchStr={ setSearchStr }
            >
                {
                    alert !== false &&
                    <Modal
                        text={ alert }
                        set={ setAlert }
                    />
                }
                <Table
                    headers={ headers }
                    data={ guides }
                    crudFunc={ crudFunc }
                    btnText="Guide"
                    setAlert={ setAlert }
                    filterField="category"
                    searchStr={ searchStr }
                    setOverlay={ setOverlay }
                />
            </Page>
        </>
    )
}