import { default as RestockPinger } from './RestockPinger'
import { default as NewBuyPinger } from './NewBuyPinger'
import { default as GuidePinger } from './GuidePinger'
import { default as ScheduledNotiPinger } from './ScheduledNotiPinger'
import { default as ProductPinger } from './ProductPinger'
import { default as NotiPinger } from './NotiPinger'
import { default as BannerPinger } from './BannerPinger'
import { default as NewProduct } from './newProduct'

export const pinger = {
    restock: RestockPinger,
    newBuy: NewBuyPinger,
    guide: GuidePinger,
    scheduledNoti: ScheduledNotiPinger,
    product: ProductPinger,
    noti: NotiPinger,
    banner: BannerPinger,
    product: NewProduct
}