import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../funcs/apiCall'
import DropDown from '../DropDown'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import TextField from '../TextField'
import TextField2 from '../TextField2'
import Button from '../Button'
import { InputCenter } from '../../funcs/Valid'
import TextTitle from '../TextTitle'
import { rgx } from '../../constants'

const log = console.log

export default function UpdateNoti (props) {

    const token = useRef( useSelector( state => state.user.token ) ).current

    const [ id, setID ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ url, setURL ] = useState('')
    const [ date, setDate ] = useState('')
    const [ time, setTime ] = useState('')
    const [ content, setContent ] = useState('')
    const [ version, setVersion ] = useState('')
    const [ creation, setCreation ] = useState({})

    const [ currentField, setCurrentField ] = useState('')
    const [ valid, setValid ] = useState({})
    const IC = useRef( new InputCenter() ).current

    const nameDict = {
        'noti_type': category,
        'Title': title,
        'URL': url,
        'Date': date,
        'Time': time,
        'Content': content,
    }

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid
        

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ title, url, date, time, content, version ])

    const loadNewBuy = async () => {
        await api.get2('get_noti', { 'jwttoken': token, 'noti_id': props.item.id }).then((res) => {
            let item = res.data

            console.log(item)
            
            setID(item.id)
            setCategory(item.noti_type)
            setTitle(item.title)
            setURL(item.ad_info)
            setContent(item.content)
            setVersion(item.version)

            let rawTime = item.time
            if (rawTime !== undefined) {
                let tmp = rawTime.split('T')
                let date = tmp[0].split('-')
                let time = tmp[1].split('.')[0].split(':')

                tmp = parseInt(time[0])

                if (tmp == 23) {
                    time[0] = 0
                }
                else {
                    time[0] = tmp
                }

                let creation = {
                    raw: item.creation_time.split('T')
                }

                creation.date = creation.raw[0].split('_')
                creation.time = creation.raw[1].split('.')[0].split(':')

                tmp = parseInt(creation.time[0])

                if (tmp == 23) {
                    creation.time[0] = 0
                }
                else {
                    creation.time[0] = tmp + 1
                }
    
                time = `${ time[0] }.${ time[1] }`
                date = `${ date[2] }.${ date[1] }.${ date[0].slice(2) }`
                creation.time = `${ creation.time[0] }:${ creation.time[1] }`
    
                setTime(time)
                setDate(date)
                setCreation({
                    creator: item.creator,
                    time: `${ creation.date } ${ creation.time }`
                })
            }
        })
    }

    useEffect(() => {
        loadNewBuy()
    }, [])

    useEffect(() => {
        let tmpValid = {}

        Object.keys(field).map((key) => {
            tmpValid[field[key].title] = true
            IC.addInput(field[key])
        })
    
        Object.keys(field).map((key) => {
            IC.setValue(field[key].title, nameDict[field[key].title])
        })

        setValid(tmpValid)
        console.log(IC)
    }, [ creation ])

    const field = useRef({
        title: {
            icon: '/imgs/icons/person.png',
            title: 'Title',
            pattern: rgx.name,
            value: title,
            canBlank: false,
            set: setTitle
        },
        url: {
            icon: '/imgs/icons/black-link.png',
            title: 'URL',
            pattern: rgx.url,
            value: url,
            canBlank: true,
            set: setURL,
            className: 'url-input',
            tooltip: 'https://example.com'
        },
        date: {
            icon: '/imgs/icons/black-link.png',
            title: 'Date',
            pattern: rgx.date,
            value: date,
            canBlank: false,
            set: setDate,
            className: 'url-input',
            tooltip: 'https://example.com'
        },
        time: {
            icon: '/imgs/icons/black-link.png',
            title: 'Time',
            pattern: rgx.time,
            value: time,
            canBlank: false,
            set: setTime,
            className: 'url-input',
            tooltip: 'https://example.com'
        },
        content: {
            icon: '/imgs/icons/black-link.png',
            title: 'Content',
            pattern: rgx.article,
            value: content,
            canBlank: false,
            set: setContent,
            className: 'url-input',
            tooltip: 'https://example.com',
            multiline: true,
        },
    }).current

    const updateNoti = async () => {

        let alertDesc = ''
        let res = await IC.allValid()
        console.log(res)

        if (res.valid) {
            try {
                await api.post('edit_noti', {
                    "jwttoken": token ,
                    'noti_id': id,
                    'noti_type': category,
                    'title': title,
                    'content': content,
                    'ad_info': url,
                    'version': version,
                    'date': date,
                    'time': time
                }).then((res) => {
                    console.log(res.data)
                    if(res.data.status == "success"){
                        alertDesc = "Notification updated!"
                    }
                    else {
                        alertDesc = res.data.error
                    }
                })
            }
            catch (e) {
                alertDesc = 'Failed to update notification.'
                console.log(e)
            }
        }
        else {
            alertDesc = res.msg
            console.log(IC.getInputs())
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        props.setOverlay(0, alert)
    }

    
    const categories = [
        { label: 'Main' , value: 'main' },
        { label: 'Staff' , value: 'staff' }
    ]

    return (
            <div className={ styles.wrap }>
                <div className={ styles.topWrap }>
                    <h1
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            opacity: '.9'
                        }}
                    >Edit Notification</h1>
                    {/* <TextTitle
                        title={ name }
                        setFunc={ setName }
                    />
                    <div className={ styles.imgOuterWrap }>
                        <div className={ styles.imgWrap }>
                            <img src={ img }/>
                        </div>
                    </div> */}
                </div>
                <div className={ styles.bottomWrap }>
                    <DropDown
                        items={ categories }
                        setValue={ setCategory }
                        icon='/imgs/icons/file.png'
                        placeholder={ category }
                        className={ styles.drop1 }
                    />
                    <TextField2
                        changeFunc={ field.title.set }
                        valid={ valid[field.title.title] }
                        label={ field.title.title }
                        placeholder={ title }
                        onFocus={ () => setCurrentField(field.title.title) }
                        big={ field.title.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.url.set }
                        valid={ valid[field.url.title] }
                        label={ field.url.title }
                        placeholder={ url }
                        onFocus={ () => setCurrentField(field.url.title) }
                        big={ field.url.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.date.set }
                        valid={ valid[field.date.title] }
                        label={ field.date.title }
                        placeholder={ date }
                        onFocus={ () => setCurrentField(field.date.title) }
                        big={ field.date.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.time.set }
                        valid={ valid[field.time.title] }
                        label={ field.time.title }
                        placeholder={ time }
                        onFocus={ () => setCurrentField(field.time.title) }
                        big={ field.time.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.content.set }
                        valid={ valid[field.content.title] }
                        label={ field.content.title }
                        placeholder={ content }
                        onFocus={ () => setCurrentField(field.content.title) }
                        big={ field.content.multiline ? true : false }
                    />
                </div>
                <Button
                    label="Update Notification"
                    onClick={ updateNoti }
                />
            </div>
    )
}