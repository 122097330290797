import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1AWrziMFM9oXx1OUGmfZnKLicNcKSVEY",
  authDomain: "ccn-app-9fe38.firebaseapp.com",
  databaseURL: "https://ccn-app-9fe38-default-rtdb.firebaseio.com",
  projectId: "ccn-app-9fe38",
  storageBucket: "ccn-app-9fe38.appspot.com",
  messagingSenderId: "300430365384",
  appId: "1:300430365384:web:f8278256068398887c4bfd",
  measurementId: "G-LFC0DJBWHS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;