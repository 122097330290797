// List of patterns scanned for
const pattern = {
    pyDate: /^([\d]{4,4}-[\d]{2,2}-[\d]{2,2})$/,
    pyDateTime: /^([\d]{4,4}-[\d]{2,2}-[\d]{2,2}[T][\d]{2,2}:[\d]{2,2}:[\d]{1,2}(|([.][\d]+[\w]))Z)$/,
    iis0date: /^([\d]{2,2}.[\d]{2,2}.[\d]{2,2})$/,
    iis0dateTime: /^([\d]{2,2}.[\d]{2,2}.[\d]{2,2} [\d]{2,2}.[\d]{2,2})$/
}

const parse = () => {

}

// Converts datatime strings to formatted object

export const getStandard = (datetime) => {

    let date = ''
    let time = ''

    let match = {
        py: pattern.pyDateTime.test(datetime),
        iis0: pattern.iis0dateTime.test(datetime)
    }

    if (match.py) {

        let splitChar = datetime.includes('T') ? 'T' : 'Z'
        let split = datetime.split(splitChar)

        date = split[0]
        date = date.split('-')
        date = date[2] + '-' + date[1] + '-' + date[0]
    
        time = split[1]
        let timeChunks = []
        time = time.split('.')[0]
        timeChunks = time.split(':')
        time = timeChunks[0] + ':' + timeChunks[1]
    }

    if (match.iis0) {
        let split = datetime.split(' ')

        date = split[0].replaceAll('.', '-')
        time = split[1].replaceAll('.', ':')
    }

    return {
        date,
        time,
        datetime: date + ' ' + time
    }
}