import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAnalyticsEventTracker } from '../App'
import { Pane } from '../components'
import DropDown from '../components/DropDown'
import Page from '../components/layouts/Page'
import StatCount from '../components/StatCount'
import Table from '../components/Table'
import { api } from '../funcs/apiCall'
import styles from '../styles/analytics.module.scss'
import { helpers } from '../constants'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const SubSection = ({ title, children }) => {

    return (
        <section
            className={ styles.section }
        >
            <div className={ styles.titleWrap }>
                <h2 className={ styles.title }>{ title }</h2>
            </div>
            { children }
        </section>
    )
}

export default function Analytics({ setOverlay }) {
    
    
    const [ stats, setStats ] = useState({})
    const [ timeFrameValue, setTimeFrameValue ] = useState(1) // actual value to send in api
    const [startDate, setStartDate] = useState(new Date()); // for custom date
    const [ timeFrameOption, setTimeFrameOption ] = useState(1) // use it to check and show date picker
    const [ showDatePicker, setShowDatePicker ] = useState(false)
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const [ items, setItems ] = useState([
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Today', value: 1 },
        { label: '7 Days', value: 7 },
        { label: 'Custom', value: 'custom'}
    ])

    const loadStats = async () => {
        // add analytic pages to specific user ids        
        if (helpers.analyticNonAllowedUserIds.indexOf(userID) > -1) {
            return;
        }
        await api.post('signup_data', { date_range: timeFrameValue, jwttoken: token }).then((res) => {

            if (res.data.status === 'success') {
                setStats(res.data)
            }
        })
    }

    
    // check if custom then show date picker
    // else set value to be called in api
    useEffect(() => {
        if (timeFrameOption == 'custom') {
            // show date picker
            setShowDatePicker(true)
        } else {
            setShowDatePicker(false)
            setTimeFrameValue(timeFrameOption)
        }
    }, [ timeFrameOption ])


    useEffect(() => {
        if (timeFrameOption == 'custom') {
            // format to yyyy-mm-dd for api
            var dateString = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000 ))
                    .toISOString()
                    .split("T")[0];
            setTimeFrameValue(dateString)
        }       
    }, [ startDate ])

    useEffect(() => {
        loadStats()
    }, [ timeFrameValue ])

    useEffect(() => {
        loadStats()
    }, [])

    return (
        <>
            <Page
                title="Analytics"
                current={ 4 }
                dropDown={{
                    items,
                    setValue: setTimeFrameValue,
                    icon: '/imgs/icons/clock.png',
                    placeholder: 'Today',
                    white: true,
                }}
                setOverlay={ setOverlay }
            >
                <div className={ styles.dropDownWrap }>
                    <DropDown
                        // key={`field-${ index }`}
                        items={ items }
                        setValue={ setTimeFrameOption }
                        // icon={ '/imgs/icons/clock.png' }
                        placeholder={ 'Today' }
                    />
                    {showDatePicker && 
                        <div style={{paddingBottom: 2, marginBottom: 12}}>
                            <DatePicker sty selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>                        
                    }
                </div>
                <div className={ styles.pageWrap }>
                    <section className='stat3'>
                        <StatCount
                            label='Total Sign Ups'
                            value={ stats.TotalSignups }
                            direction='up'
                        />
                        <StatCount
                            label='Apple Sign Ups'
                            value={ stats.AppleSignups }
                            direction='up'
                        />
                        <StatCount
                            label='Word Press Sign Ups'
                            value={ stats.WpSignups }
                            direction='up'
                        />
                    </section>
                    {/* <SubSection
                        title='Notifications'
                    >
                        <section
                            className={ styles.horizontalSection }
                        >
                            <Pane
                                flex={ 2 }
                            >
                                asdf
                            </Pane>
                            <Pane
                                flex={ 3.46 }
                            >
                                asdf
                            </Pane>
                            <Table
                            />
                        </section>
                    </SubSection> */}
                </div>
            </Page>
        </>
    )
}