import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/layouts/Page'
import SlideUp from '../components/SlideUp'
import UpdateRestock from '../components/slideUps/updateRestock'
import Table from '../components/Table'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import { api } from '../funcs/apiCall'
import { actions } from '../redux/actions'
import styles from '../styles/appfeed.module.scss'
import { crud, CrudControl } from '../lib/crud'
import datetime from '../lib/time'
import Modal from '../components/Modal'
import { rgx } from '../constants'
import { pinger } from '../components/slideUps/pinger'

export default function Restocks({ setOverlay }) {

    const dispatch = useDispatch()

    const userName = useRef( useSelector( state => state.user.name ) ).current
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const refresh = useSelector( state => state.refresh.run )
    const refreshPage = useSelector( state => state.refresh.page )

    const [alert, setAlert] = useState(false)
    const [searchStr, setSearchStr] = useState('')

    // Table data

    const [notis, setNotis] = useState([])
    const [headers, setHeaders] = useState([])

    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current

    const setupTable = () => {

        let headData = []

        if (!isMobile()) {
            headData = [
                { title: 'Image', field: 'image'},
                { title: 'Name', field: 'name'},
                { title: 'Category',  field: 'admin'},
                { title: 'Date', field: 'date'},
                { title: 'Time', field: 'time'},
                { title: 'Publisher', field: 'publisher'},
            ]
        }
        else {
            headData = [
                { title: 'Image', field: 'image'},
                { title: 'Name', field: 'name'},
            ]
        }

        setHeaders(headData)
    }

    const loadNotis = async () => {

        let { success, data, msg } = await crudControl.readRestock()

        try {
            if (success) {
                let parsed = data.map((record) => {

                    let created = datetime.getStandard(record.upload_time)
                    
                    return {
                        id: record.id,
                        image: record.image,
                        admin: record.adminonly == "true" ? 'Admin' : (record.lifetime == "true" ? 'Lifetime' : 'Main'),
                        date: created.date,
                        time: created.time,
                        name: record.title,
                        publisher: record.uploaded_by ? record.uploaded_by : 'Bot',
                        url: record.store_link
                    }
                })

                setNotis(parsed)
            }
            else {
                console.error(msg)
            }
        }
        catch (e) {
            console.error(e)
            setNotis([])
        }
    }

    const crudFunc = {
        create: <pinger.restock setOverlay={ setOverlay } load={ loadNotis }/>,
        read: (record) => window.open(record.url, '_blank'),
        update: (record) =>  {
            setOverlay(2, <UpdateRestock setOverlay={ setOverlay } item={ notis.filter((item) => item.id == record.id)[0] }/>)
        },
        delete: async (itemList, name = userName) => crudControl.deleteRestock(name, itemList, loadNotis),
    }

    useEffect(() => {
        setupTable()
        loadNotis()
    }, [])

    useEffect(() => {
        loadNotis()

        if ((refreshPage == 'restocks') && refresh) {
            loadNotis()
            dispatch(actions.setRefresh(false))
        }
    }, [ refresh ])

    return (
        <>
            <Page
                title='Restocks'
                current={0}
                setOverlay={setOverlay}
                setSearchStr={ setSearchStr }
            >
                {
                    notis &&
                    <Table
                        headers={ headers }
                        data={ notis }
                        crudFunc={ crudFunc }
                        btnText="Restock"
                        setAlert={ setAlert }
                        filterField="admin"
                        searchStr={ searchStr }
                        setOverlay={ setOverlay }
                    />
                }
            </Page>
        </>
    )
}