import { useState, useEffect, useRef  } from 'react'
import Button from '../../Button'
import TextField from '../../TextField'
import styles from '../../../styles/components/slideUps/newProduct.module.scss'
import { api } from '../../../funcs/apiCall'
import { InputCenter } from '../../../funcs/Valid'
import { rgx } from '../../../constants'
import { useSelector } from 'react-redux'
import Modal from '../../Modal'

export default function ProductPinger({ setOverlay }) {

    const defaultImg = useRef('https://cdn.discordapp.com/attachments/889814604190978108/961213852706570330/Icon_material-file-upload3x.png').current
    const token = useRef( useSelector( state => state.user.token ) ).current
    const [ name, setName ] = useState('')
    const [ resellValue, setResellValue ] = useState('')
    const [ img, setImg ] = useState(defaultImg)
    const [ parsedImg, setParsedImg ] = useState()

    const [ currentField, setCurrentField ] = useState('')

    const [ valid, setValid ] = useState({})

    const nameDict = {
        'Product Name': name,
        'Resell Value': resellValue
    }

    const IC = useRef( new InputCenter() ).current

    const changeHandler = (e) => {

        setImg(e.target.files[0])
        setParsedImg(URL.createObjectURL(e.target.files[0]))
    }

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ name, resellValue ])

    const fields = useRef([
        {
            icon: '',
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName
        },
        {
            icon: '',
            title: 'Resell Value',
            pattern: rgx.price,
            value: resellValue,
            canBlank: false,
            set: setResellValue
        },
    ]).current

    const addImg = async () => {

        let alertDesc = ''
        let res = await IC.allValid()

        if (res.valid) {
            const data = new FormData()

            data.append("name", name)
            data.append("jwttoken", token )
            data.append("image", img)
            data.append("resell_value", resellValue)

            try {
                await api.post('addimage', data).then((res) => {
                    if (res.data.status == "success") {
                        alertDesc = 'New product added!'
                    }
                })
            }
            catch (e) {
                alertDesc = 'Failed to add product.'
                console.log('ERROR: ', e)
            }
        }
        else {
            alertDesc = res.msg
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        setOverlay(0, alert)
    }

    useEffect(() => {
        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        setValid(tmpValid)
    }, [])

    return (
        <Modal setOverlay={ setOverlay }>
            <div className={ styles.wrap }>
                <div
                    className={ styles.imgWrap }
                    onClick={ () => document.getElementById('img-selector').click() }
                >
                    {
                        img === defaultImg &&
                        <img src={ defaultImg } className={ styles.img }/>
                    }
                    {
                        img !== defaultImg &&
                        <img src={ parsedImg } className={ styles.img }/>
                    }
                </div>
                <input
                    type='file'
                    id='img-selector'
                    hidden
                    accept='image/png, image/gif, image/jpeg'
                    onChange={ changeHandler }
                />
                {
                    fields.map((field) => (
                        <TextField
                            changeFunc={ field.set }
                            valid={ valid[field.title] }
                            placeholder={ field.title }
                            icon={ field.icon }
                            focus={ currentField == field.title }
                            onFocus={ () => setCurrentField(field.title) }
                        />
                    ))
                }
                <Button
                    label='Add Product'
                    onClick={ addImg }
                />  
            </div>
        </Modal>
    )
}