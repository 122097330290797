import {useRef } from 'react'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import TextField2 from '../TextField2'
import { rgx } from '../../constants'

const log = console.log

export default function ViewNotifStats(props) {


    const field = useRef({
        title: {
            icon: '/imgs/icons/person.png',
            title: 'Title',
            pattern: rgx.name,
            value: props.notif.title,
            canBlank: false,
        },
        message: {
            icon: '/imgs/icons/person.png',
            title: 'Message',
            pattern: rgx.name,
            value: props.notif.message,
            canBlank: false,
        },
        sent_time: {
            icon: '/imgs/icons/person.png',
            title: 'Sent Time',
            pattern: rgx.name,
            value: props.notif.sent_time,
            canBlank: false,
        },
        date: {
            icon: '/imgs/icons/person.png',
            title: 'Date',
            pattern: rgx.name,
            value: props.notif.date,
            canBlank: false,
        },
        ping_category: {
            icon: '/imgs/icons/person.png',
            title: 'Category',
            pattern: rgx.name,
            value: props.notif.ping_category,
            canBlank: false,
        },

    }).current



    const categories = [
        { label: 'Main', value: 'main' },
        { label: 'Staff', value: 'staff' }
    ]

    return (
        <div className={styles.wrap}>
            <div className={styles.topWrap}>
                <h1
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        opacity: '.9'
                    }}
                >Notification</h1>
                {/* <TextTitle
                        title={ name }
                        setFunc={ setName }
                    />
                    <div className={ styles.imgOuterWrap }>
                        <div className={ styles.imgWrap }>
                            <img src={ img }/>
                        </div>
                    </div> */}
            </div>
            <div className={styles.bottomWrap}>

                <TextField2
                    label={field.title.title}
                    value={field.title.value}
                    placeholder={field.title.value}
                    big={field.title.multiline ? true : false}
                />

                <TextField2
                    label={field.message.title}
                    value={field.message.value}
                    placeholder={field.message.value}
                    big={true}
                />


                <TextField2
                    label={field.date.title}
                    value={field.date.value}
                    placeholder={field.date.value}
                    big={field.title.multiline ? true : false}
                />

                <TextField2
                    label={field.sent_time.title}
                    value={field.sent_time.value}
                    placeholder={field.sent_time.value}
                    big={field.title.multiline ? true : false}
                />

                <TextField2
                    label={field.ping_category.title}
                    value={field.ping_category.value}
                    placeholder={field.ping_category.value}
                    big={field.title.multiline ? true : false}
                />

            </div>

        </div>
    )
}