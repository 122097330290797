import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rgx } from '../constants'
import { isMobile } from '../funcs'
import styles from '../styles/components/table.module.scss'
import Button from './Button'
import DropDown from './DropDown'
import Modal from './Modal'

// All the body of the table

const DataFeed = ({ headers, data, crudFunc, setCurrent, deleteHandler }) => {

    const [head, setHead] = useState([])
    const [records, setRecords] = useState([])

    useEffect(() => {
        setHead(headers)
        setRecords(data)
    }, [headers])

    return (
        <tbody>
            {
                data &&
                data.map((row, index) => (
                    <Row
                        headers={headers}
                        record={row}
                        crudFunc={crudFunc}
                        key={`row-${index}`}
                        setCurrent={setCurrent}
                        deleteHandler={deleteHandler}
                    />
                ))
            }
        </tbody>
    )
}

// A single record in the table

const Row = ({ headers, record, crudFunc, setCurrent, deleteHandler }) => {

    const [delModal, setDelModal] = useState(false)
    const setOverlay = useRef(useSelector(state => state.overlay.set))

    const [meta] = useState({
        token: useSelector(state => state.user.token)
    })

    const randomID = () => {
        return Math.ceil(Math.random() * 10)
    }

    const clickHandler = () => {
        if (isMobile()) {
            setCurrent(record)
        }
    }

    return (
        <tr
            className={styles.tableRow} key={`tr-${randomID()}`}
            onClick={clickHandler}
        >
            {
                headers.map((col) => (
                    <td key={`td-${randomID()}-${col.field}`} className={col.field == 'image' ? styles.imageTD : ''}>
                        <div className={styles.dataWrap}>
                            {
                                col.field == 'image' &&
                                <img src={record[col.field]} />
                            }
                            {
                                ['time'].includes(col.field) &&
                                <div className={styles.timeDataWrap}>
                                    <div className={styles.bubble}>
                                        {record[col.field]}
                                    </div>
                                </div>
                            }
                            {
                                !['image', 'time'].includes(col.field) ?
                                    <div className={styles.textDataWrap}>
                                        {record[col.field]}
                                    </div> : ''
                            }
                        </div>
                    </td>
                ))
            }
            <td>
                <div className={styles.crudWrap}>
                    <span
                        className={styles.crudBtn}
                        onClick={() => crudFunc.read(record)}
                    >
                        <img src='./imgs/icons/eye.svg' />
                    </span>
                    <span
                        className={styles.crudBtn}
                        onClick={() => crudFunc.update(record)}
                    >
                        <img src='./imgs/icons/pencil2.svg' />
                    </span>
                    <span
                        className={styles.crudBtn}
                        onClick={() => deleteHandler(record)}
                    >
                        <img src='./imgs/icons/bin.svg' />
                    </span>
                </div>
            </td>
        </tr>
    )
}

const MobileItem = ({ current, setCurrent, crudFunc, deleteHandler }) => (
    <div className={styles.mobileItem}>
        <div className={styles.topWrap}>
            <button
                onClick={() => setCurrent(false)}
            >
                {/* <img src='/imgs/icons/back-arrow-2.png'/> */}

                <img src='/imgs/icons/close.svg' />
            </button>
        </div>
        {
            current.image &&
            <div className={styles.imgOuterWrap}>
                <div className={styles.imgWrap}>
                    <img src={current.image} className />
                </div>
            </div>
        }
        {
            current.name &&
            <h1>{current.name}</h1>
        }
        {
            current.title &&
            <h1>{current.title}</h1>
        }
        {
            current !== false &&
            Object.keys(current).map((field, index) => {
                if (field !== 'title' && field !== 'image' && field !== 'name') {
                    return (
                        <div className={styles.mobileField} >
                            <h3 className={styles.title}>{field[0].toUpperCase() + field.slice(1)}</h3>
                            <p className={styles.value}>{current[field]}</p>
                        </div>
                    )
                }
            })
        }
        <div className={styles.crudWrap}>
            <span
                className={styles.crudBtn}
                onClick={() => crudFunc.read(current)}
            >
                <img src='./imgs/icons/eye.svg' />
            </span>
            <span
                className={styles.crudBtn}
                onClick={() => crudFunc.update(current)}
            >
                <img src='./imgs/icons/pencil2.svg' />
            </span>
            <span
                className={styles.crudBtn}
                onClick={() => deleteHandler(current)}
            >
                <img src='./imgs/icons/bin.svg' />
            </span>
        </div>
    </div>
)

export default function Table({ headers, data, crudFunc, btnText, setAlert, filterField, searchStr, setOverlay }) {

    const [hasImg, setHasImg] = useState(false)
    const [delModal, setDelModal] = useState(false)
    const [current, setCurrent] = useState(false)
    const [filter, setFilter] = useState('All')
    const [filteredData, setFilteredData] = useState(data)
    const [filterOptions, setFilterOptions] = useState([])
    const [dataBuffer, setDataBuffer] = useState([])
    const [delList, setDelList] = useState([])

    const refreshFilter = () => {

        let newData = []

        if (filterField) {
            if (filter.toLowerCase() == 'all') {
                newData = data
            }
            else {
                newData = data.filter((record) => record[filterField].toLowerCase() == filter.toLowerCase())
            }

            setFilteredData(newData)
            setDataBuffer(newData)
        }
    }

    // Function for filtering unique values
    const unique = (value, index, self) => {
        return self.indexOf(value) === index
    }

    const runFilter = () => {

        // Initiates filter options
        try {

            if (filterField) {
                let all = data.map((item) => item[filterField])
                let uniqueOptions = all.filter(unique)

                let options = uniqueOptions.map((option) => ({
                    label: option[0].toUpperCase() + option.slice(1),
                    value: option.toLowerCase()
                }))

                setFilterOptions([
                    { label: 'All', value: 'all' },
                    ...options
                ])
            }
        }
        catch (e) {
            console.error(e)
        }

        setFilteredData(data)
    }

    const runSearchFilter = () => {

        try {
            if (!rgx.blank.test(searchStr)) {
                let search = new RegExp(`${searchStr.toLowerCase()}`, 'g')

                setFilteredData(dataBuffer.filter((record) => {

                    if (record.name) {
                        console.log('Aloha')
                        return search.test(record.name.toLowerCase())
                    }

                    if (record.title) {
                        return search.test(record.title.toLowerCase())
                    }
                }))
            }
            else {
                runFilter()
            }
        }
        catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {

        // Initiates table headers
        headers.forEach((item) => {
            if (item.field.toLowerCase() == 'image') {
                setHasImg(true)
            }
        })

        setFilteredData(data)
    }, [])

    useEffect(() => {
        refreshFilter()
        runFilter()
    }, [data])

    useEffect(() => {
        refreshFilter()
    }, [filter])

    useEffect(() => {
        runSearchFilter()
    }, [searchStr])

    const deleteHandler = (item) => {

        setOverlay(0, {
            desc: 'Are you sure you want to delete this item?',
            setFunc: () => crudFunc.delete([item]),
            btns: [
                {
                    label: 'No',
                    returnValue: false
                },
                {
                    label: 'Yes',
                    returnValue: true
                },
            ]
        })
    }

    const deleteManyHandler = () => {

        setOverlay(0, {
            desc: 'Are you sure you want to delete these items?',
            setFunc: () => crudFunc.delete(delList),
            btns: [
                {
                    label: 'No',
                    returnValue: false
                },
                {
                    label: 'Yes',
                    returnValue: true
                },
            ]
        })
    }

    const showCreateModal = () => {
        setOverlay(3, crudFunc.create)
    }

    return (
        <>
            <div className={styles.controls}>
                {/* <span> */}
                <DropDown
                    className={styles.filter}
                    placeholder='All'
                    items={filterOptions}
                    setValue={setFilter}
                />
                {/* </span> */}

                <span className={styles.functions}>
                    <span className={styles.pageNav}>

                    </span>
                    <Button
                        class={styles.overlayTrigger}
                        onClick={showCreateModal}
                        fit={true}
                    >
                        <p
                            style={{
                                fontSize: '1.5rem',
                                color: 'white',
                                marginRight: '1rem',
                                marginLeft: '-.5rem',
                                marginTop: '.2rem'
                            }}
                        >+</p>
                        {btnText}
                    </Button>
                </span>
            </div>
            <section className={styles.bigWrap}>
                <div
                    className={`${styles.innerWrap} ${current == false ? styles.inactiveItem : styles.activeItem}`}
                >
                    <div className={styles.tableWrap}>
                        <table className={styles.table}>
                            <thead className={styles.header}>
                                <tr>
                                    {
                                        headers !== undefined &&
                                        headers.map((item, index) => <th className={item.title.toLowerCase() == 'image' ? styles.imageHeader : ''} key={`table-header-${item.field}`}>{item.title}</th>)
                                    }
                                    <th className={styles.crudCol}>
                                        {/* {
                                            true &&
                                            <>
                                                <Button
                                                    label="Delete"
                                                    onClick={ deleteManyHandler }
                                                    class={ styles.delBtn }
                                                />
                                            </>
                                        } */}
                                    </th>
                                </tr>
                            </thead>
                            <DataFeed
                                headers={headers}
                                data={filteredData}
                                hasImg={hasImg}
                                crudFunc={crudFunc}
                                setCurrent={setCurrent}
                                deleteHandler={deleteHandler}
                            />
                        </table>
                    </div>
                    {
                        isMobile() &&
                        <MobileItem
                            current={current}
                            setCurrent={setCurrent}
                            crudFunc={crudFunc}
                            deleteHandler={deleteHandler}
                        />
                    }
                </div>
            </section>
        </>
    )
}