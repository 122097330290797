export class InputCenter {
    constructor() {
        this.inputList = {}
    }

    isValid(title) {
        try {
            return this.inputList[title].valid()
        }
        catch (e) {
            return { error: 'Title not found'}
        }
    }

    async allValid() {
        let res = {}

        for (const title in this.inputList) {
            res = this.isValid(title)

            try {
                // consol
                if (!res.valid) {
                    return res
                }
            }
            catch (e) {
                console.log(e)
                return res
            }
        }

        return res
    }

    setValue(title, value) {
        try {
            if (value == null) {
                return this.inputList[title].setValue('')
            }

            return this.inputList[title].setValue(value)
        }
        catch (e) {
            return { error: 'Title not found'}
        }
    }

    async setPattern(title, pattern) {
        let target = this.inputList[title]
        this.inputList[title] = new Input(target.title, pattern, target.value, target.canBlank)
    }

    async setCanBlank(title, canBlank) {
        let target = this.inputList[title]
        this.inputList[title] = new Input(target.title, target.pattern, target.value, canBlank)
    }

    getValue(title) {
        try {
            return this.inputList[title].getValue()
        }
        catch (e) {
            return { error: 'Title not found'}
        }
    }

    async setInputs(inputs) {
        inputs.map((item) => {
            // Stops duplicates from being stored
            if (Object.keys(this.inputList).includes(item.title)) {
                return
            }

            this.inputList[item.title] = new Input(item.title, item.pattern, (item.value != null ? item.value : ''), item.canBlank)
        })
    }

    addInput(item) {
        // Stops duplicates from being stored
        if (Object.keys(this.inputList).includes(item.title)) {
            return
        }

        this.inputList[item.title] = new Input(item.title, item.pattern, (item.value != null ? item.value : ''), item.canBlank)
        // console.log(item.title, item.pattern, item.value, item.canBlank)
    }

    getInputs() {
        return this.inputList
    }
}

export class Input {
    constructor(title, pattern, value, canBlank=false) {
        this.title = title.charAt(0).toUpperCase() + title.slice(1)
        this.pattern = RegExp(pattern, 'i')
        this.value = value
        this.canBlank = canBlank
    }

    valid() {
        let valid = this.pattern.test( this.value )
        let msg = `${this.title} field has an invalid input.`

        if ( !this.canBlank ) {
            console.log(this.title)
            let blank = (/^\s*$/.test(this.value))
            valid = valid && !blank

            if ( !valid ) {
                msg = blank ? `${ this.title } field is blank.` : msg
            }
        }
        else {
            valid = valid || (/^\s*$/.test(this.value))
        }

        return { valid, msg }
    }

    setValue(value) {
        this.value = value
    }

    getValue() {
        return this.value
    }
}