import { getFirestore, collection, getDocs  } from "firebase/firestore";
import app from '../config/index'
import { query, orderBy, limit } from "firebase/firestore";  

const db = getFirestore(app);

const notifCollection = "notification";

const getNotificationData = async () => {
    const collectionRef = collection(db, notifCollection);
    const collectionQuery = await query(collectionRef, orderBy('sent_time', 'desc'), limit(100));
    const querySnapshot = await getDocs(collectionQuery);

    return querySnapshot;    
}

export { getNotificationData };