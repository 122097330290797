import { useState, useEffect } from "react"
import styles from '../styles/components/textfield2.module.scss'
import Tooltip from "./Tooltip"


export default function TextTitle({ title, setFunc, classN, tooltip }) {
    const [ value, setValue ] = useState(title)
    const [ active, setActive ] = useState(false)

    useEffect(() => {

        let page = document.getElementsByTagName('body')[0]
        let input = document.getElementsByClassName('text-tile-input')[0]

        try {
            input.value = title;
            input.focus()
        }
        catch (e) {
        }
    })

    return (
        <>
            {
                !active &&
                <h1
                    onClick={ () => setActive(true) }
                    style={{
                        // textAlign: 'left',
                        fontWeight: 'medium',
                        fontSize: '1.4rem'
                    }}
                >
                    { title }
                </h1>
            }
            {
                active &&
                <span className={`${styles.wrap} ${ styles.focus }`}>
                    <input
                        className={`text-tile-input ${ styles.input }`}
                        onClick={ () => setActive(true) }
                        onChange={ (e) => setFunc(e.target.value) }
                        onBlur={ () => setActive(false) }
                    />
                </span>
            }
        </>
    )
}