// Restocks

import { api } from "../../funcs"
import { useDispatch } from "react-redux"


export const restock = async (token, props, callBack = async () => {}) => {

    let success = true
    let msg = 'Your ping has been sent!'

    const create = async () => {

        let newProps = {
            ...props,
            "jwttoken": token
        }

        
        await api.post('notificationadd', newProps).then((res) => {
            
            let { status, error } = res.data

            console.log(res.data)

            success = status === "success"

            if ( !success || error ){
                try {
                    msg = error ? error : "Notification failed"
                }
                catch (e) {
                    msg = "An error has occured."

                    console.error(e)
                }
            }
        })
    }
    
    await create()
    await callBack()

    return {
        success,
        msg
    }
}

// New Buys

export const newBuy = async (token, props, callBack = async () => {}) => {
    
    let success = true
    let msg = 'Your new buy has been added!'

    // Defining asynchronous functions like this curves the need to make the actual function asynchronous, whilst still providing all the benefits of async/await
    const create = async () => {

        let newProps = {
            ...props,
            "jwttoken": token
        }

        await api.post('add_newbuy', newProps).then((res) => {

            let { status, error } = res.data

            success = status === "success"

            console.log(res.data)

            if (!success || error) {
                try {
                    msg = error ? error : "Failed to add new buy."
                }
                catch (e) {
                    msg = "An error has occured."

                    console.error(e)
                }
            }
        })
    }
    
    await create()
    await callBack()

    return {
        success,
        msg
    }
}

// Guides

export const guide = async (token, props, callBack = async () => {}) => {
    
    let success = true
    let msg = 'Your guide has been added!'

    const create = async () => {

        let newProps = {
            ...props,
            "jwttoken": token
        }

        await api.post('add_guide_news', newProps).then((res) => {

            let { status, error } = res.data

            success = status === "success"

            console.log(res.data)

            if (!success || error) {
                try {
                    msg = error ? error : "Failed to add guide."
                }
                catch (e) {
                    msg = "An error has occured."

                    console.error(e)
                }
            }
        })
    }
    
    await create()
    await callBack()

    return {
        success,
        msg
    }
}

// Notis

export const product = async (token, props, callBack = async() => {}) => {
    
    let success = true
    let msg = 'Your notification has been scheduled.'

    const create = async () => {


        let newProps = {
            ...props,
            "jwttoken": token
        }

        await api.post('schedule_noti', newProps).then((res) => {

            let { status, error } = res.data

            success = status === "success"

            console.log(res.data)

            if (!success || error) {
                try {
                    msg = error ? error : "Failed to schedule notification."
                }
                catch (e) {
                    msg = "An error has occured"

                    console.error(e)
                }
            }
        })
    }
    
    await create()
    await callBack()

    return {
        success,
        msg
    }
}

// Products

export const noti = async (token, props, callBack = async() => {}) => {
    
    let success = true
    let msg = 'Your notification has been scheduled.'

    console.log('NOTI START')

    const create = async () => {

        console.log('NOTI CREATE')

        let newProps = {
            ...props,
            "jwttoken": token
        }

        await api.post('schedule_noti', newProps).then((res) => {

            let { status, error } = res.data

            success = status === "success"
            console.log('NOTI SENT')

            console.log(res.data)

            if (!success || error) {
                try {
                    msg = error ? error : "Failed to schedule notification."
                }
                catch (e) {
                    msg = "An error has occured"

                    console.error(e)
                }
            }
        })
    }
    
    await create()
    await callBack()

    return {
        success,
        msg
    }
}