import { api } from "../../funcs"
import axios from "axios"

export const restock = async (name, token, itemList, callBack = async () => {}) => {

    let success = true
    let msg = itemList.length === 1 ? 'Notification deleted!' : 'Notifications deleted!'

    const deleteRestock = async (item) => {

        await api.post('notificationdelete', { "jwttoken": token, "notifid": item.id }).then((res) => {
    
            if (res.data.status === 'deleted') {
    
                axios.post("https://discord.com/api/webhooks/946788182450663425/sWO5t8nQTXFT05W93eboSnmrWLId5pPwOmyjv8epGKO1NcCcr51K_Rt2B-r4Gr2puMGL",
                    {
                        "content": null,
                        "embeds": [
                            {
                                "title": "Notification Deleted",
                                "color": 10609016,
                                "fields": [
                                    {
                                        "name": "Item:",
                                        "value": item.title
                                    },
                                    {
                                        "name": "Notification Ping Time:",
                                        "value": item.time
                                    },
                                    {
                                        "name": "Deleted By:",
                                        "value": name
                                    },
    
                                ],
                                "thumbnail": {
                                    "url": item.image
                                }
                            }
                        ],
                    }
                )
            }
            else {
                success = false
                msg = `Failed to delete notification\nItem ID: ${item.id}`
            }
        }).catch((e) => {
            success = false
            msg = `An error has occurred.`

            console.error(e)
        })
    }

    for (let i=0; i<itemList.length; i++) {
        await deleteRestock(itemList[i])
    }

    await callBack()

    return {
        success,
        msg
    }
}

// New Buys

export const newBuy = async (token, itemList, callBack = async () => {}) => {
    
    let success = true
    let msg = itemList.length == 1 ? 'New buy deleted!' : 'New buys deleted!'

    const deleteNewBuy = async (item) => {

        await api.post('delete_newbuy', { "jwttoken": token, "newbuy_id": item.id }).then((res) => {

            if (res.data.status !== 'deleted') {
                success = false
                msg = `Failed to delete notification\nItem ID: ${item.id}`
            }
        }).catch((e) => {
            success = false
            msg = 'An error has occured.'

            console.error(e)
        })
    }

    for (let i=0; i<itemList.length; i++) {
        await deleteNewBuy(itemList[i])
    }

    await callBack()

    return {
        success,
        msg
    }
}

// Guides

export const guide = async (token, itemList, callBack = async () => {}) => {

    let success = true
    let msg = itemList.length == 1 ? 'Guide deleted!' : 'Guides deleted!'

    const deleteGuide = async (item) => {

        await api.post('delete_guide_news', { "jwttoken": token, "news_guide_id": item.id }).then((res) => {

            if (res.data.status !== 'deleted') {
                success = false
                msg = 'Failed to delete guide'
            }
        }).catch((e) => {
            success = false
            msg = 'An error has occured'
            console.error(e)
        })
    }

    for (let i=0; i<itemList.length; i++) {
        await deleteGuide(itemList[i])
    }

    await callBack()

    return {
        success,
        msg
    }
}

// Notis

export const noti = async (token, itemList, callBack = async () => {}) => {

    let success = true
    let msg = itemList.length === 1 ? 'Notification deleted!' : 'Notifications deleted!'

    const deleteNoti = async (item) => {

        await api.post('delete_noti', { "jwttoken": token, "noti_id": item.id }).then((res) => {

            if (res.data.status !== 'deleted') {
                success = false
                msg = 'Failed to delete notification'
            }
        }).catch((e) => {
            success = false
            msg = 'An error has occured.'
            console.error(e)
        })
    }

    for (let i=0; i<itemList.length; i++) {
        await deleteNoti(itemList[i])
    }

    await callBack()

    return {
        success,
        msg
    }
}

// Products

export const product = async (token, itemList, callBack = async () => {}) => {

    let success = true
    let msg = itemList.length === 1 ? 'Notification deleted!' : 'Notifications deleted!'

    const deleteProduct = async (item) => {

        await api.post('delete_product', { "jwttoken": token, "product_id": item.id }).then((res) => {

            if (res.data.status !== 'deleted') {
                success = false
                msg = 'Failed to delete notification'
            }
        }).catch((e) => {
            success = false
            msg = 'An error has occured.'
            console.error(e)
        })
    }

    for (let i=0; i<itemList.length; i++) {
        await deleteProduct(itemList[i])
    }

    await callBack()

    return {
        success,
        msg
    }
}