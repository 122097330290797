import * as deleteOp from './delete'
import * as create from './create'
import * as read from './read'
import * as update from './update'


export const crud = {
    createRestock: create.restock,
    createNewBuy: create.newBuy,
    createGuide: create.guide,
    createNoti: create.noti,
    readRestock: read.restock,
    readNewBuy: read.newBuy,
    readGuide: read.guide,
    readNoti: read.noti,
    updateRestock: update.restock,
    updateNewBuy: update.newBuy,
    updateGuide: update.guide,
    updateNoti: update.noti,
    deleteRestock: deleteOp.restock,
    deleteNewBuy: deleteOp.newBuy,
    deleteGuide: deleteOp.guide,
    deleteNoti: deleteOp.noti
}

export { CrudControl } from './CrudControl'