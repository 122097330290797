import { api } from "../../funcs"

const update = async (token, endpoint, props, callBack = async() => {}) => {

    let success = true
    let msg = 'Data has been updated!'
    
    const updateRecord = async () => {

        let allProps = {
            ...props,
            jwttoken: token
        }

        await api.post(endpoint, allProps).then((res) => {
            
            let { status, error } = res.data

            success = status == "success"

            if (!success) {
                try {
                    msg = error ? error : "Failed to update."
                }
                catch (e) {
                    msg = "An error has occured!"

                    console.error(e)
                }
            }
        })
    }

    await updateRecord()
    await callBack()

    return {
        success,
        msg
    }
}

// Restocks

export const restock = async (token, props, callBack = async() => {}) => update(token, 'update_noti', props, callBack)

// New Buys

export const newBuy = async (token, props, callBack = async() => {}) => update(token, 'update_newbuy', props, callBack)

// Guides

export const guide = async (token, props, callBack = async() => {}) => update(token, 'update_guide', props, callBack)

// Notis

export const noti = async (token, props, callBack = async() => {}) => update(token, 'edit_noti', props, callBack)