import './App.scss'
import { Provider, useDispatch } from 'react-redux'
import { persistor, store } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import { useEffect, useState } from 'react'

import WelcomePage from './pages/welcome-page'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import UsersPage from './pages/users-page'
import AppFeed from './pages/Restocks'
import Login from './pages/Login'
import Settings from './pages/Settings'
import NewBuys from './pages/NewBuys'
import News from './pages/News'
import Notifications from './pages/Notifications'
import Analytics from './pages/Analytics'
import SlideUp from './components/SlideUp'
import Alert from './components/Alert'
import RightSlide from './components/RightSlide'

import ReactGA from 'react-ga'
import Products from './pages/Products'
import AnalyticsSeller from './pages/AnalyticsSeller'
import NotificationsStats from './pages/NotificationsStats'

export const useAnalyticsEventTracker = (category = '') => {
    const eventTracker = (action = 'test', label = 'label') => {
        ReactGA.event({ category, action, label })
    }

    return eventTracker
}


const Overlay = ({ active, AlertObj, SlideUpObj, RightSlideObj, ModalObj, setOverlay }) => {

    const overlayTime = 50
    const innerTime = 300

    const setupListener = () => {

        if (active) {
            const overlay = document.getElementsByClassName('overlay')[0]
    
            overlay.addEventListener('click', (e) => {

                const classList = e.target.classList
                const classStr = classList.toString()

                const close = (res) => {
                    if (res) {
                        setOverlay()
                    }
                } 
        
                if (classStr.includes('overlay')) {
                    let alert = {
                        desc: 'Are you sure you want to close this screen?',
                        setFunc: close,
                        btns: [
                            {
                                label: 'No',
                                returnValue: false
                            },
                            {
                                label: 'Yes',
                                returnValue: true
                            }
                        ]
                    }
        
                    setOverlay(0, alert)
                }
            })
        }
    }

    useEffect(() => {
        const overlay = document.getElementsByClassName('overlay')[0]
        
        if (active) {
            setTimeout(() => {
                overlay.classList.add('reveal')
            }, overlayTime)
        }
        else {
            setTimeout(() => {
                try {
                    overlay.classList.remove('reveal')
                }
                catch (e) {}
            }, innerTime)
        }

        setupListener()
    }, [ active ])

    return (
        <>
            {
                active &&
                <div className='overlay'>
                    {
                        AlertObj !== null &&
                        <Alert
                            desc={ AlertObj.desc }
                            setFunc={ AlertObj.setFunc }
                            alertBuffer={ setOverlay }
                            btns={ AlertObj.btns }
                            setOverlay={ setOverlay }
                        />
                    }
                    {
                        SlideUpObj !== null &&
                        <SlideUp buffer={ setOverlay }>
                            { SlideUpObj }
                        </SlideUp>
                    }
                    {
                        RightSlideObj !== null &&
                        <RightSlide buffer={ setOverlay }>
                            { RightSlideObj }
                        </RightSlide>
                    }
                    {
                        ModalObj !== null &&
                        <>
                            { ModalObj }
                        </>
                    }
                </div>
            }
        </>
    )
}

function InnerApp() {

    const dispatch = useDispatch()

    const [ AlertObj, setAlert ] = useState(null)
    const [ SlideUpObj, setSlideUp ] = useState(null)
    const [ RightSlideObj, setRightSlide ] = useState(null)
    const [ blur, setBlur ] = useState(false)
    const [ ModalObj, setModal ] = useState(null)
    const [ overlay, switchOverlay ] = useState(false)

    const setOverlay = (type = -1, value = null) => {

        let overlayObj = { type: '', value: ''}

        switch (type) {
            case 0:
                overlayObj = {
                    type: 'alert',
                    value
                }
                setAlert(value)
                break
            case 1:
                overlayObj = {
                    type: 'slideUp',
                    value
                }
                setSlideUp(value)
                break
            case 2:
                overlayObj = {
                    type: 'slideRight',
                    value
                }
                setRightSlide(value)
                break
            case 3:
                overlayObj = {
                    type: 'modal',
                    value
                }
                setModal(value)
                break
        }


        if (type == -1) {
            switchOverlay(false)
            setAlert(null)
            setSlideUp(null)
            setRightSlide(null)
            setModal(null)
        }
        else {
            switchOverlay(true)
        }
    }

    return (
        <>
            <Overlay
                active={ overlay }
                AlertObj={ AlertObj }
                SlideUpObj={ SlideUpObj }
                RightSlideObj={ RightSlideObj }
                ModalObj={ ModalObj }
                setOverlay={ setOverlay }
            />
            <BrowserRouter>
                <Routes>
                    <Route path="" element={<WelcomePage setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Login" element={<Login setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="New-Buys" element={<NewBuys setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Products" element={<Products setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Guides" element={<News setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Restocks" element={<AppFeed setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Users" element={<UsersPage setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Notifications" element={<Notifications setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Notif_Stats" element={<NotificationsStats setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Analytics" element={<Analytics setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Analytics_seller" element={<AnalyticsSeller setOverlay={ setOverlay } blur={ blur } />} />
                    <Route path="Settings" element={<Settings setOverlay={ setOverlay } blur={ blur } />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}


const App = () => (
    <Provider store={ store }>
        <PersistGate loading={ null } persistor={ persistor }>
            <InnerApp />
        </PersistGate>
    </Provider>
)

export default App;
