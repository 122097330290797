import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../funcs/apiCall'
import Button from '../Button'
import TextField from '../TextField'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import DropDown from '../DropDown'
import { InputCenter } from '../../funcs/Valid'
import { shouldRefresh } from '../../funcs'
import TextField2 from '../TextField2'
import { rgx } from '../../constants'
import TextTitle from '../TextTitle'
import Modal from '../Modal'


export default function UpdateRestock(props) {
    
    const token = useRef( useSelector( state => state.user.token ) ).current

    const [ category, setCategory ] = useState('')
    const [ confirm, setConfirm ] = useState(false)
    const [ name, setName ] = useState('')
    const [ storeLink, setStoreLink ] = useState('')
    const [ newStoreLink, setNewStoreLink ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ price, setPrice ] = useState('')
    const [ resell, setResell ] = useState('')
    const [ notes, setNotes ] = useState('')
    const [ id, setID ] = useState('')
    const [ alert, setAlert ] = useState(false)

    const [ currentField, setCurrentField ] = useState('')
    const [ valid, setValid ] = useState({})

    const nameDict = {
        'Price': price,
        'Resell Value': resell,
        'Store Link': newStoreLink,
        'Notes': notes
    }

    const IC = useRef( new InputCenter() ).current

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }


    // £119 -> 119
    const filterCurrency = (value) => {
        console.log(value);
        if (value && value.indexOf('£') > -1) {
            return value.substring(1);
        }

        return value
    }

    const loadRestocks = async () => {
        
        await api.get2('get_restock', { 'jwttoken': token, 'noti_id': props.item.id }).then((res) => {
            let item = res.data
            
            setID(item.id)
            setName(item.title)
            setImg(item.image)
            setPrice(filterCurrency(item.price))
            setResell(filterCurrency(item.resell_value))
            setNotes(item.notes)
            setNewStoreLink(item.store_link)
            setStoreLink(item.store_link)
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ price, resell, newStoreLink, notes ])

    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const fields = useRef([
        {
            title: 'Price',
            pattern: rgx.price,
            value: price,
            canBlank: false,
            set: setPrice
        },
        {
            title: 'Resell Value',
            pattern: rgx.price,
            value: resell,
            canBlank: true,
            set: setResell
        },
        {
            title: 'Store Link',
            pattern: rgx.url,
            value: newStoreLink,
            canBlank: false,
            set: setNewStoreLink
        },
        {
            title: 'Notes',
            pattern: rgx.article,
            value: notes,
            canBlank: true,
            set: setNotes
        },
    ]).current
    
    const [ items, setItems ] = useState([
        { label: 'News', value: 'news' },
        { label: 'Sneakers', value: 'sneakers' },
        { label: 'Console', value: 'console' },
        { label: 'Hardware', value: 'hardware' },
        { label: 'Outdoor', value: 'outdoor' },
        { label: 'Miscellaneous', value: 'misc' },
        { label: 'Developer Testing', value: 'devtest' },
    ])

    const updateNoti = async () => {


        console.log('L - 3')

        let alertDesc = ''
        let res = await IC.allValid()

        console.log(res)
        console.log(notes)
        console.log(IC)

        if (res.valid) {
            await api.post('update_noti', {
                "title": name,
                "store_link": storeLink,
                "image": img,
                // "store": store,
                "price": price,
                "resell_value": resell,
                "notes": notes,
                "noti_id": id,
                "jwttoken": token
            }).then((res) => {
                if(res.data.status === "success"){
                    alertDesc = "Notification updated!"
                }
                else {
                    alertDesc = "Update failed."
                }

                console.log(res.data)
            })
        }
        else {
            alertDesc = res.msg
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => props.setOverlay(0, null),
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: true
                },
            ]
        }

        localStorage.setItem('refreshPage', 'restocks')
        props.setOverlay(0, alert)
    }

    const confirmAlert = () => {
        let alert = {
            desc: 'Are you sure you want to make these changes?',
            setFunc: updateNoti,
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'No',
                    returnValue: false
                },
                {
                    label: 'Yes',
                    returnValue: true
                },
            ],
        }

        props.setOverlay(0, alert)
    }

    useEffect(() => {

        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        fields.map((field) => {
            IC.setValue(field.title, nameDict[field.title])
        })

        setValid(tmpValid)
    }, [ storeLink ])

    useEffect(() => {
        loadRestocks()
    }, [])

    useEffect(() => {

        if (!notes) {
            setNotes('')
        }

    }, [notes])

    return (
        <div className={ styles.wrap }>
            {
                alert !== false &&
                <Modal
                    text={ alert.text }
                    btns={ alert.btns }
                />
            }
            <div className={ styles.topWrap }>
                <h1
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        opacity: '.9'
                    }}
                >Edit Restock</h1>
                <div className={ styles.imgOuterWrap }>
                    <div className={ styles.imgWrap }>
                        <img src={ img } className/>
                    </div>
                </div>
                <TextTitle
                    title={ name }
                    setFunc={ setName }
                />
            </div>
            <div className={ styles.bottomWrap }>
                <div>
                    <TextField2
                        changeFunc={ fields[0].set }
                        valid={ valid[fields[0].title] }
                        label={ fields[0].title }
                        placeholder={ price }
                        unit='£'
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[0].title) }
                    />
                    <TextField2
                        changeFunc={ fields[1].set }
                        valid={ valid[fields[1].title] }
                        label={ fields[1].title }
                        placeholder={ resell }
                        unit='£'
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[1].title) }
                    />
                    <TextField2
                        changeFunc={ fields[2].set }
                        valid={ valid[fields[2].title] }
                        label={ fields[2].title }
                        placeholder={ storeLink }
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[2].title) }
                    />
                    <TextField2
                        changeFunc={ fields[3].set }
                        valid={ valid[fields[3].title] }
                        label={ fields[3].title }
                        placeholder={ notes }
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[3].title) }
                    />
                </div>
                <Button
                    label="Update Notification"
                    onClick={ confirmAlert }
                    class={ styles.sendBtn }
                />
            </div>
        </div>
    )
}