import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../funcs/apiCall'
import Button from '../Button'
import TextField from '../TextField'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import DropDown from '../DropDown'
import { InputCenter } from '../../funcs/Valid'
import { shouldRefresh } from '../../funcs'
import TextField2 from '../TextField2'
import { rgx } from '../../constants'
import TextTitle from '../TextTitle'


export default function UpdateGuide(props) {
    
    const token = useRef( useSelector( state => state.user.token ) ).current

    const [ loaded, setLoaded ] = useState(false)
    const [ category, setCategory ] = useState('')
    const [ confirm, setConfirm ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ article, setArticle ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ id, setID ] = useState('')

    const [ currentField, setCurrentField ] = useState('')
    const [ valid, setValid ] = useState({})

    const nameDict = {
        'Title': title,
        'Image': img,
        'Article': article
    }

    const IC = useRef( new InputCenter() ).current

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid
        console.log(IC)

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ title, img, article ])

    const loadGuide = async () => {
        await api.get2('guide_news_history', { 'jwttoken': token }).then((res) => {
            let item = res.data.find(x => x["id"] === props.item.id)

            setCategory(item.category)
            setID(item.id)
            setTitle(item.title)
            setImg(item.image_url)
            setArticle(item.article)
        })
    }

    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const field = useRef({
        title: {
            title: 'Title',
            pattern: rgx.name,
            value: title,
            canBlank: false,
            set: setTitle
        },
        img: {
            title: 'Image',
            pattern: rgx.url,
            value: img,
            canBlank: true,
            set: setImg
        },
        article: {
            title: 'Article',
            pattern: rgx.article,
            value: article,
            canBlank: true,
            set: setArticle
        },
    }).current
    
    const [ items, setItems ] = useState([
        { label: 'Learn', value: 'Learn'},
        { label: 'News', value: 'News'},
    ])

    const updateGuide = async () => {

        let alertDesc = ''
        let res = await IC.allValid()

        console.log(res)
        
        if (res.valid) {
            await api.post('update_guide', {
                "title": title,
                "image_url": img,
                "article": article,
                "category": category,
                "guide_id": id,
                "jwttoken": token
            }).then((res) => {
                console.log(res)
                if(res.data.status === "success"){
                    alertDesc = "Guide updated!"
                }
                else {
                    alertDesc = "Update failed."
                }

                console.log(res.data)
            })
        }
        else {
            alertDesc = res.msg
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        localStorage.setItem('refreshPage', 'guides')
        props.setOverlay(0, alert)
    }

    const confirmAlert = () => {
        let alert = {
            desc: 'Are you sure you want to make these changes?',
            setFunc: setConfirm,
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'No',
                    returnValue: false
                },
                {
                    label: 'Yes',
                    returnValue: true
                },
            ]
        }

        props.setOverlay(0, alert)
        setConfirm(false)
    }

    useEffect(() => {
        if (confirm) {
            updateGuide()
        }
    }, [ confirm ])

    useEffect(() => {
        loadGuide()

        setInterval(() => {
                
            let refreshPage = localStorage.getItem('refreshPage')
            if (refreshPage == 'guides') {
                localStorage.setItem('refreshPage', '')
                loadGuide()
            }
        }, 1000)
    }, [])

    useEffect(() => {

        // if (!loaded) {
            let tmpValid = {}
    
            Object.keys(field).map((key) => {
                IC.addInput(field[key])
                tmpValid[field[key].title] = true
            })
    
            Object.keys(field).map((key) => {
                IC.setValue(field[key].title, nameDict[field[key].title])
            })
    
            setValid(tmpValid)

            // setLoaded(true)
        // }
    }, [ article ])

    useEffect(() => {
        console.log(IC)
    }, [ valid ])

    return (
        <div className={ styles.wrap }>
            <div className={ styles.topWrap }>
                <h1
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        opacity: '.9'
                    }}
                >Edit Guide</h1>
                <div className={ styles.imgOuterWrap }>
                    <div className={ styles.imgWrap }>
                        <img src={ img } className/>
                    </div>
                    <TextTitle
                        title={ title }
                        setFunc={ setTitle }
                    />
                </div>
            </div>
            <div className={ styles.bottomWrap }>
                <div>
                    <DropDown
                        items={ items }
                        setValue={ setCategory }
                        icon='/imgs/icons/file.png'
                        placeholder={ category }
                        className={ styles.drop1 }
                    />
                    <TextField2
                        changeFunc={ field.img.set }
                        valid={ valid[field.img.title] }
                        label={ field.img.title }
                        placeholder={ img }
                        onFocus={ () => setCurrentField(field.img.title) }
                    />
                    <TextField2
                        changeFunc={ field.article.set }
                        valid={ valid[field.article.title] }
                        label={ field.article.title }
                        placeholder={ article }
                        big={true}
                        onFocus={ () => setCurrentField(field.article.title) }
                    />
                </div>
                <Button
                    label="Update Guide"
                    onClick={ confirmAlert }
                    class={ styles.sendBtn }
                />
            </div>
        </div>
    )
}