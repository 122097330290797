import {
    SET_NAME,
    SET_TOKEN,
    SET_EMAIL,
    SET_ID,
    SET_REFRESH,
    SET_REFRESH_PAGE,
    SET_OVERLAY_FUNC,
    SET_CURRENT_OVERLAY
} from './types'

import { combineReducers } from "redux"

const userReducer = (state = { name: '', id: -1 }, action) => {

    switch (action.type) {
        case SET_NAME:
            return {
                ...state,
                name: action.payload
            }
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case SET_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case SET_ID:
            return {
                ...state,
                id: action.payload
            }
        default:
            return state
    }
}

const refreshReducer = (state = { page: '', refresh: false  }, action) => {

    switch (action.type) {
        case SET_REFRESH:
            return {
                ...state,
                run: action.payload
            }
        case SET_REFRESH_PAGE:
            return {
                ...state,
                page: action.payload
            }
        default:
            return state
    }
}

const overlayReducer = (state = { }, action) => {
    
    switch (action.type) {
        case SET_OVERLAY_FUNC:
            return {
                ...state,
                set: action.payload
            }
        case SET_CURRENT_OVERLAY:
            return {
                ...state,
                current: action.payload
            }
        default:
            return state
    }
}

const rootReducer = combineReducers({
    user: userReducer,
    refresh: refreshReducer,
    overlay: overlayReducer
})

export default rootReducer