import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/layouts/Page'
import SlideUp from '../components/SlideUp'
import UpdateRestock from '../components/slideUps/updateRestock'
import Table from '../components/Table'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import { api } from '../funcs/apiCall'
import { actions } from '../redux/actions'
import styles from '../styles/appfeed.module.scss'
import { crud, CrudControl } from '../lib/crud'
import datetime from '../lib/time'
import Modal from '../components/Modal'
import { rgx } from '../constants'
import { pinger } from '../components/slideUps/pinger'
import UpdateProduct from '../components/slideUps/updateProduct'

export default function Products({ setOverlay }) {

    const dispatch = useDispatch()

    const userName = useRef( useSelector( state => state.user.name ) ).current
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const refresh = useSelector( state => state.refresh.run )
    const refreshPage = useSelector( state => state.refresh.page )

    const [alert, setAlert] = useState(false)
    const [searchStr, setSearchStr] = useState('')

    // Table data

    const [products, setProducts] = useState([])
    const [headers, setHeaders] = useState([])

    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current

    const setupTable = () => {

        let headData = []

        if (!isMobile()) {
            headData = [
                { title: 'ID', field: 'id' },
                { title: 'Image', field: 'image' },
                { title: 'Name', field: 'name'},
                { title: 'Resell',  field: 'resell_value'},
                { title: 'Ebay Sold url',  field: 'ebay_sold_link'},
            ]
        }
        else {
            headData = [
                { title: 'Image', field: 'image' },
                { title: 'Name', field: 'name'},
            ]
        }

        setHeaders(headData)
    }

    const loadProducts = async () => {
        let { success, data, msg } = await crudControl.readProduct()

        try {
            if (success) {
                let parsed = data.products.map((record) => {
                    const { resell_value } = record
                    
                    return {
                        id: record.id,
                        ebay_sold_link: record.ebay_sold_link,
                        image: record.id !== 33 ? `https://dk1adr1q40rtx.cloudfront.net/appmedia/${record.image_name}` : '',
                        resell_value: (resell_value == "" || resell_value == null) ? '' : resell_value,
                        name: record.product_name,
                        image_name: record.image_name
                        // date: created.date,
                    }
                })

                setProducts(parsed)
            }
            else {
                console.error(msg)
            }
        }
        catch (e) {
            console.error(e)
            setProducts([])
        }
    }

    const crudFunc = {
        create: <pinger.product setOverlay={ setOverlay } load={ loadProducts }/>,
        read: (record) => {}, //window.open(record.ebay_sold_link, '_blank'),
        update: (record) =>  {
            setOverlay(2, <UpdateProduct setOverlay={ setOverlay } item={ products.filter((item) => item.id == record.id)[0] }/>)
        },
        delete: async (itemList) => crudControl.deleteProduct(itemList, loadProducts),
    }

    useEffect(() => {
        setupTable()
        loadProducts()
    }, [])

    useEffect(() => {
        loadProducts()

        if ((refreshPage == 'products') && refresh) {
            loadProducts()
            dispatch(actions.setRefresh(false))
        }
    }, [ refresh ])

    return (
        <>
            <Page
                title='Products'
                current={0}
                setOverlay={setOverlay}
                setSearchStr={ setSearchStr }
            >
                {
                    products &&
                    <Table
                        headers={ headers }
                        data={ products }
                        crudFunc={ crudFunc }
                        btnText="Product"
                        setAlert={ setAlert }
                        filterField="name"
                        searchStr={ searchStr }
                        setOverlay={ setOverlay }
                    />
                }
            </Page>
        </>
    )
}