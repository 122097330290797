import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CrudControl } from '../../../lib/crud'
import { rgx } from '../../../constants'
import { Modal } from '../..'
import { actions } from '../../../redux/actions'

export default function RestockPinger({ load, setOverlay }) {

    const dispatch = useDispatch()

    const userName = useRef( useSelector( state => state.user.name ) ).current
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current
    const defaultImg = 'https://cdn.discordapp.com/attachments/1013742632138186802/1013743282209181766/unknown.png'

    const [alert, setAlert] = useState(false)

    // Field data
    
    const [ category, setCategory ] = useState({ label: 'Select Category', value: '' })
    const [ name, setName ] = useState('')
    const [ url, setURL ] = useState('')
    const [ store, setStore ] = useState('')
    const [ img, setImg ] = useState('')
    const [ price, setPrice ] = useState('')
    const [ resell, setResell ] = useState('')

    const categoryItems = [
        { label: 'News', value: 'news' },
        { label: 'Sneakers', value: 'sneakers' },
        { label: 'Console', value: 'console' },
        { label: 'Hardware', value: 'hardware' },
        { label: 'Outdoor', value: 'outdoor' },
        { label: 'Miscellaneous', value: 'misc' },
        { label: 'Developer Testing', value: 'devtest' },
    ]

    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current

    const fields = [
        {
            type: 'dropdown',
            title: "category",
            items: categoryItems,
            placeholder: 'Category',
            pattern: rgx.article,
            canBlank: false,
            value: category,
            set: setCategory
        },
        {
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName,
        },
        {
            title: 'Product URL',
            pattern: rgx.url,
            value: url,
            canBlank: false,
            set: setURL,
        },
        {
            title: 'Store',
            pattern: /^(.+)$/g,
            value: store,
            canBlank: false,
            set: setStore,
        },
        {
            title: 'Image URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: img,
            canBlank: true,
            set: setImg,
        },
        {
            title: 'Price',
            pattern: rgx.price,
            value: price,
            canBlank: false,
            set: setPrice,
        },
        {
            title: 'Resell',
            pattern: rgx.tbcOrPrice,
            value: resell,
            canBlank: true,
            set: setResell,
        }
    ]


    const restockProps = {
        "title": name,
        "url": url,
        "image": rgx.blank.test(img) ? defaultImg : img,
        "store": store,
        "price": price,
        "category": category,
        "resell_value": resell,
        "jwttoken": token.jwt,
        "user_id": userID
    }

    const setup = () => {
        dispatch(actions.setRefreshPage('restocks'))
    }

    const triggerRefresh = () => {
        dispatch(actions.setRefresh(true))
    }

    useEffect(() => {
        setup()
    }, [])

    return (
        <>
            <Modal
                inputs={ fields }
                inputsPerPage={ 4 }
                title={ "Create Restock" }
                set={ (l) => {} }
                func={ async () => await crudControl.createRestock(restockProps, triggerRefresh ) }
                setAlert={ setAlert }
                setOverlay={ setOverlay }
            />
        </>
    )
}