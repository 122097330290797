import { useState, useEffect, useRef } from 'react'
import { api, Input } from '../../funcs'
import DropDown from '../DropDown'
import styles from '../../styles/components/slideUps/newBuyPinger.module.scss'
import TextField from '../TextField'
import Button from '../Button'
import { InputCenter } from '../../funcs/Valid'
import { rgx } from '../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../redux/actions'

const log = console.log

export default function NewBuyPinger (props) {

    const dispatch = useDispatch()

    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const [ category, setCategory ] = useState('')
    const [ releaseType, setReleaseType ] = useState('')
    const [ name, setName ] = useState('')
    const [ url, setURL ] = useState('')
    const [ retail, setRetail ] = useState('')
    const [ resell, setResell ] = useState('')
    const [ releaseDate, setReleaseDate ] = useState('')
    const [ releaseTime, setReleaseTime ] = useState('')
    const [ startReleaseTime, setStartReleaseTime ] = useState('')
    const [ endReleaseTime, setEndReleaseTime ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ discordImg, setDiscordImg ] = useState('')
    const [ desc, setDesc ] = useState('')
    const [ sellingTime, setSellingTime ] = useState('')
    const [ returns, setReturns ] = useState('')
    const [ shipping, setShipping ] = useState('')
    const [ soldListing, setSoldListing ] = useState('')
    const [ whereToSell, setWhereToSell ] = useState('')
    const [ categorySet, setCategorySet ] = useState(false)
    const [ releaseTypeSet, setReleaseTypeSet ] = useState(false)

    const isVisibleField = (field) => {

        let date1 = ['Default' ]
        let date2 = [ 'Range' ]
        let exception = 'average selling time'
        let title = field.title.toLowerCase()

        if ((title.includes('date') || title.includes('time')) && title !== exception) {

            if (date1.includes(releaseType) && field.dateTime && field.dateCount == 1) {
                return true
            }

            if (date2.includes(releaseType) && field.dateTime && field.dateCount == 2) {
                return true
            }

            return false
        }

        return true
    }

    const [ currentField, setCurrentField ] = useState('Product Name')

    const IC = useRef( new InputCenter() ).current

    const [ valid, setValid ] = useState({})

    const nameDict = {
        'Product Name': name,
        'Product URL': url,
        'Retail': retail,
        'Resell': resell,
        'Release Date': releaseDate,
        'Release Time': releaseTime,
        'Start Time': startReleaseTime,
        'End Time': endReleaseTime,
        'Image URL': img,
        'Discord Image (URL)': discordImg,
        'Description': desc,
        'Average Selling Time': sellingTime,
        'Returns': returns,
        'Shipping Cost (£)': shipping,
        'Ebay Sold Listing URL': soldListing,
        'Where To Sell': whereToSell
    }
    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid
        
        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ name, url, retail, resell, releaseDate, releaseTime, img, desc, returns, shipping, soldListing, whereToSell, sellingTime, category, discordImg, startReleaseTime, endReleaseTime ])

    useEffect(() => {
        if (category !== '' ) {
            setCategorySet(true)
        }
    }, [ category ])

    useEffect(() => {
        if (releaseType !== '' ) {
            setReleaseTypeSet(true)
        }

        switch (releaseType) {
            case 'Live':
                IC.setCanBlank('Release Date', true)
                IC.setCanBlank('Release Time', true)
                IC.setCanBlank('Start Time', true)
                IC.setCanBlank('End Time', true)
                break;
            case 'Default':
                IC.setCanBlank('Release Date', false)
                IC.setCanBlank('Release Time', false)
                IC.setCanBlank('Start Time', true)
                IC.setCanBlank('End Time', true)
                break;
            case 'Range':
                IC.setCanBlank('Release Date', false)
                IC.setCanBlank('Release Time', true)
                IC.setCanBlank('Start Time', false)
                IC.setCanBlank('End Time', false)
                break;
            case 'Coming Soon':
                IC.setCanBlank('Release Date', true)
                IC.setCanBlank('Release Time', true)
                IC.setCanBlank('Start Time', true)
                IC.setCanBlank('End Time', true)
                break;
            case 'Previously Released':
                IC.setCanBlank('Release Date', true)
                IC.setCanBlank('Release Time', true)
                IC.setCanBlank('Start Time', true)
                IC.setCanBlank('End Time', true)
        }
    }, [ releaseType ])

    const fields = useRef([
        {
            icon: '/imgs/icons/person.png',
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Product URL',
            pattern: rgx.url,
            value: url,
            canBlank: true,
            set: setURL
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Retail',
            pattern: rgx.price,
            value: retail,
            canBlank: false,
            className: 'retail',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setRetail
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Resell',
            pattern: rgx.price,
            value: resell,
            canBlank: false,
            className: 'resell',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setResell
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: true,
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 1,
            className: 'release-date-1',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 2,
            className: 'release-time-1',
            tooltip: 'hh.mm',
            set: setReleaseTime
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            className: 'release-date-2',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Start Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: startReleaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 1,
            className: 'start-time',
            tooltip: 'hh.mm',
            set: setStartReleaseTime
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'End Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: endReleaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 2,
            className: 'end-time',
            tooltip: 'hh.mm',
            set: setEndReleaseTime
        },
        {
            icon: '/imgs/icons/img.png',
            title: 'Image URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: img,
            canBlank: false,
            set: setImgBuffer
        }, 
        {
            icon: '/imgs/icons/discord.png',
            title: 'Discord Image (URL)',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: discordImg,
            canBlank: false,
            set: setDiscordImg
        }, 
        {
            icon: '/imgs/icons/pencil.png',
            title: 'Description',
            pattern: /^(.|[\n\r\t])+$/g,
            value: desc,
            canBlank: false,
            set: setDesc,
            multiline: true
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Average Selling Time',
            pattern: /^(.+)$/g,
            value: sellingTime,
            canBlank: false,
            set: setSellingTime
        },
        {
            icon: '/imgs/icons/returns.png',
            title: 'Returns',
            pattern: /^(.+)$/g,
            value: returns,
            canBlank: false,
            set: setReturns
        },
        {
            icon: '/imgs/icons/lorry.png',
            title: 'Shipping Cost (£)',
            pattern: /^(\d+)$/g,
            value: shipping,
            canBlank: false,
            set: setShipping
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Ebay Sold Listing URL',
            pattern: /^((tbc|TBC)|(https?:\/\/(www\.)?ebay\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)))$/g,
            value: soldListing,
            canBlank: false,
            set: setSoldListing
        },
        {
            icon: '/imgs/icons/store.png',
            title: 'Where To Sell',
            pattern: /^(.+)$/g,
            value: whereToSell,
            canBlank: true,
            set: setWhereToSell
        }
    ]).current

    const textFields = fields
    // const [ open, setOpen ] = useState(false)
    const items = useRef([
        { label: 'Main', value: 'main' },
        { label: 'Lifetime', value: 'lifetime' },
        { label: 'Flip Test', value: 'fliptest' },
        { label: 'Developer Testing', value: 'devtest' },
    ]).current

    const releaseTypeItems = useRef([
        { label: 'Default', value: 'Default' },
        { label: 'Range', value: 'Range' },
        { label: 'Out Now', value: 'Out Now' },
        { label: 'Coming Soon', value: 'Coming Soon' },
        { label: 'Previously Released', value: 'Previously Released' },
    ]).current

    const addNewBuy = async () => {

        let alertDesc = 'ERROR'
        let res = await IC.allValid()

        if (categorySet && releaseTypeSet) {
            if (res.valid) {
                try {
                    await api.post('add_newbuy', {
                        "jwttoken": token ,
                        "product_name": name,
                        "url": url,
                        "price": retail,
                        "resell_value": resell,
                        "release_time": ['Live', 'Random'].includes(releaseType) ? '' : ( releaseType == 'Default' ? releaseDate + ' ' + releaseTime : releaseDate + ' ' + startReleaseTime + ' ' + endReleaseTime),
                        "release_type": releaseType,
                        "image_url":  img,
                        "discord_image": discordImg,
                        "return_policy": returns,
                        "description": desc,
                        "where_to_sell": whereToSell,
                        "ebay_listings": soldListing,
                        "shipping_cost":  shipping,
                        "selling_time": sellingTime,
                        "category": category,
                        "user_id": userID
                    }).then((res) => {
                        console.log(res.data)
                        if(res.data.status == "success"){
                            alertDesc = "Your ping has been sent!"
                        }
                        else {
                            alertDesc = res.data.error
                        }
                    })
                }
                catch (e) {
                    alertDesc = 'Failed to add new buy.'
                    console.log(e)
                }
            }
            else {
                alertDesc = res.msg
            }
        }
        else {
            alertDesc = categorySet ? "Set a release type!" : "Set a category!"
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        localStorage.setItem('refreshPage', 'new buys')
        // dispatch( actions.)
        props.setOverlay(0, alert)
    }

    useEffect(() => {
        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        setValid(tmpValid)
    }, [])

    return (
            <div className={ styles.wrap }>
                <div className={ styles.innerWrap }>
                    <DropDown
                        items={ items }
                        setValue={ setCategory }
                        icon='/imgs/icons/file.png'
                        placeholder='Product Category'
                        className={ styles.drop1 }
                    />
                    <DropDown
                        items={ releaseTypeItems }
                        setValue={ setReleaseType }
                        icon='/imgs/icons/file.png'
                        placeholder='Release Type'
                        className={ styles.drop2 }
                    />
                    {
                        textFields.map((field) => (
                            <>
                                {
                                    isVisibleField(field) && 
                                    <TextField
                                        changeFunc={ field.set }
                                        valid={ valid[field.title] }
                                        placeholder={ field.title }
                                        icon={ field.icon }
                                        focus={ currentField == field.title }
                                        onFocus={ () => setCurrentField(field.title) }
                                        big={ field.multiline ? true : false }
                                        inline={ field.inline ? true : false }
                                        colCount={ field.colCount ? field.colCount : 0 }
                                        class={ field.className ? field.className : null }
                                        tooltip={ field.tooltip ? field.tooltip : null }
                                    />
                                }
                            </>
                        ))
                    }
                </div>
                <Button
                    label="Send Notification"
                    onClick={ addNewBuy }
                />
            </div>
    )
}