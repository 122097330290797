import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CrudControl } from '../../../lib/crud'
import { rgx } from '../../../constants'
import { Modal } from '../..'
import { actions } from '../../../redux/actions'

export default function GuidePinger({ load, setOverlay }) {

    const dispatch = useDispatch()
    
    const token = useRef( useSelector( state => state.user.token )  ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const [alert, setAlert] = useState(false)

    // Field data

    const [ title, setTitle ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ url, setURL ] = useState('')
    const [ imgURL, setImgURL ] = useState('')
    const [ desc, setDesc ] = useState('')

    const fields = [
        {
            title: 'Title',
            pattern: /^(.+)$/g,
            value: title,
            canBlank: false,
            set: setTitle,
            icon: ''
        },
        {
            title: 'Image URL',
            pattern: rgx.url,
            value: imgURL,
            canBlank: false,
            set: setImgURL,
            icon: '/imgs/icons/img.png'
        },
        {
            title: 'Article',
            pattern: rgx.article,
            value: desc,
            canBlank: false,
            set: setDesc,
            icon: '/imgs/icons/pencil.png',
            big: true
        },
    ]

    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current

    const guideProps = {
        "title": title,
        "image_url": imgURL,
        "article": desc,
        "category": category,
        "user_id": userID
    }

    const setup = () => {
        dispatch(actions.setRefreshPage('guides'))
    }

    const triggerRefresh = () => {
        dispatch(actions.setRefresh(true))
    }

    useEffect(() => {
        setup()
    }, [])
    
    return (
        <>
            <Modal
                inputs={ fields }
                inputsPerPage={ 6.3 }
                title={ "Create Guide" }
                set={ (l) => {} }
                func={ async () => await crudControl.createGuide(guideProps, triggerRefresh) }
                setAlert={ setAlert }
                setOverlay={ setOverlay  }
            />
        </>
    )
}