import { useState, useRef, useEffect } from 'react'
import styles from '../styles/components/tooltip.module.scss'

export default function Tooltip({ id, className, children }) {

    const [ active, setActive ] = useState(false)
    const [ visible, setVisible ] = useState(false)
    const wrappedCName = useRef(className != undefined ? className + '-toolTip' :  '').current

    useEffect(() => {

        let target = ''

        if (id) {
            target = document.getElementById(id)
        }
        else if (className) {
            target = document.getElementsByClassName(className)[0]
        }
        else {
            console.error('Tool tips require an id or class property')
            return
        }

        if (target != undefined) {
            target.addEventListener('mouseenter', (e) => {
                setActive(true)
            })
            target.addEventListener('mouseleave', (e) => {
                setActive(false)
            })
        }
    }, [])

    useEffect(() => {


        try {
            let target = ''
    
            if (id) {
                target = document.getElementById(id)
            }
            else if (className) {
                target = document.getElementsByClassName(className)[0]
            }
            
            if (wrappedCName != '') {
                let rect = target.getBoundingClientRect() 
                let wrap = document.getElementsByClassName(wrappedCName)[0]
                wrap.style.left = (rect.right + 5) + 'px'
                wrap.style.top = rect.top + 'px'
                wrap.style.opacity = 1
                setVisible(true)
            }
        }
        catch (e) {

        }
    })

    return (
        <>
            {
                (active) &&
                <p className={`${ styles.wrap } ${ wrappedCName }`}>
                    { children }
                </p>
            }
        </>
    )
}