import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../funcs/apiCall'
import DropDown from '../DropDown'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import TextField from '../TextField'
import TextField2 from '../TextField2'
import Button from '../Button'
import { InputCenter } from '../../funcs/Valid'
import TextTitle from '../TextTitle'

const log = console.log

const TimeSection = ({ releaseType, valid, field, setCurrentField, value }) => {

    const key = {
        'default': [ true, true, false, false, false ],
        'range': [ false, false, true, true, true ],
        'out now': [],
        'coming soon': [],
        'previously released': [],
        'expired': [],
    }
    const type = releaseType.toLowerCase()
    return (
        <>
            {
                key[type][0] &&
                <TextField2
                    changeFunc={ field.releaseDate.set }
                    valid={ valid[field.releaseDate.title] }
                    label={ field.releaseDate.title }
                    placeholder={ value.releaseDate }
                    onFocus={ () => setCurrentField(field.releaseDate.title) }
                    big={ field.releaseDate.multiline ? true : false }
                />
            }
            {
                key[type][1] &&
                <TextField2
                    changeFunc={ field.releaseTime.set }
                    valid={ valid[field.releaseTime.title] }
                    label={ field.releaseTime.title }
                    placeholder={ value.releaseTime }
                    onFocus={ () => setCurrentField(field.releaseTime.title) }
                    big={ field.releaseTime.multiline ? true : false }
                />
            }
            {
                key[type][2] &&
                <TextField2
                    changeFunc={ field.releaseDate2.set }
                    valid={ valid[field.releaseDate2.title] }
                    label={ field.releaseDate2.title }
                    placeholder={ value.releaseDate2 }
                    onFocus={ () => setCurrentField(field.releaseDate2.title) }
                    big={ field.releaseDate2.multiline ? true : false }
                />
            }
            {
                key[type][3] &&
                <TextField2
                    changeFunc={ field.startReleaseTime.set }
                    valid={ valid[field.startReleaseTime.title] }
                    label={ field.startReleaseTime.title }
                    placeholder={ value.startReleaseTime }
                    onFocus={ () => setCurrentField(field.startReleaseTime.title) }
                    big={ field.startReleaseTime.multiline ? true : false }
                />
            }
            {
                key[type][4] &&
                <TextField2
                    changeFunc={ field.endReleaseTime.set }
                    valid={ valid[field.endReleaseTime.title] }
                    label={ field.endReleaseTime.title }
                    placeholder={ value.endReleaseTime }
                    onFocus={ () => setCurrentField(field.endReleaseTime.title) }
                    big={ field.endReleaseTime.multiline ? true : false }
                />
            }
        </>
    )
}

export default function UpdateNewBuy (props) {

    const token = useRef( useSelector( state => state.user.token ) ).current

    const [ msgLink, setMsgLink ] = useState('')
    const [ category, setCategory ] = useState('')
    const [ releaseType, setReleaseType ] = useState('')
    const [ name, setName ] = useState('')
    const [ url, setURL ] = useState('')
    const [ retail, setRetail ] = useState('')
    const [ resell, setResell ] = useState('')
    const [ releaseDate, setReleaseDate ] = useState('')
    const [ releaseTime, setReleaseTime ] = useState('')
    const  [ startReleaseTime, setStartReleaseTime  ] = useState('')
    const  [ endReleaseTime, setEndReleaseTime ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ discordImg, setDiscordImg ] = useState('')
    const [ desc, setDesc ] = useState('')
    const [ sellingTime, setSellingTime ] = useState('')
    const [ returns, setReturns ] = useState('')
    const [ shipping, setShipping ] = useState('')
    const [ soldListing, setSoldListing ] = useState('')
    const [ whereToSell, setWhereToSell ] = useState('')
    const [ freshLink, setFreshLink ] = useState(true)

    const [ lastField, setLastField ] = useState('')
    const [ currentField, setCurrentField ] = useState('')
    const [ valid, setValid ] = useState({})
    const IC = useRef( new InputCenter() ).current

    const nameDict = {
        'Message Link': msgLink,
        'Product Name': name,
        'Product URL': url,
        'Retail': retail,
        'Resell': resell,
        'Release Time': releaseTime,
        'Release Type': releaseType,
        'Image URL': img,
        'Discord Image (URL)': discordImg,
        'Description': desc,
        'Average Selling Time': sellingTime,
        'Returns': returns,
        'Shipping Cost (£)': shipping,
        'Ebay Sold Listing URL': soldListing,
        'Where To Sell': whereToSell
    
    }

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid
        

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ name, url, retail, resell, releaseDate, img, desc, returns, shipping, soldListing, whereToSell, sellingTime, category, msgLink, discordImg ])

    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const loadNewBuy = async () => {
        await api.get2('get_newbuy', { 'jwttoken': token, 'newbuy_id': props.item.id }).then((res) => {
            let item = res.data
            console.log(res.data)
            setCategory(item.category)
            setReleaseType(item.release_type)
            setName(item.product_name)
            setURL(item.url)
            setRetail(item.price)
            setResell(item.resell_value)
            setImg(item.image_url)
            setDesc(item.description)
            setSellingTime(item.selling_time)
            setReturns(item.return_policy)
            setShipping(item.shipping_cost)
            setSoldListing(item.ebay_listings)
            setWhereToSell(item.where_to_sell)
            setDiscordImg(item.discord_image)
            setReleaseTime(item.release_time)
            setMsgLink(item.msg_link)

            if (item.msg_link != '' & item.msg_link != null) {
                setFreshLink(false)
            }
            let timeChunk = item.release_time.split(' ')
            
            if (timeChunk.length == 2) {
                setReleaseDate(timeChunk[0])
                setReleaseTime(timeChunk[1])
            }
            else if (timeChunk.length == 3) {
                setReleaseDate(timeChunk[0])
                setStartReleaseTime(timeChunk[1])
                setEndReleaseTime(timeChunk[2])
            }
        })
    }

    useEffect(() => {
        loadNewBuy()

        let tmpValid = {}

        Object.keys(field).map((key) => {
            tmpValid[field[key].title] = true
        })

        IC.addInput(field.msgLink)

        setValid(tmpValid)
    }, [])

    const field = useRef({
        name: {
            icon: '/imgs/icons/person.png',
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName
        },
        url: {
            icon: '/imgs/icons/link-black.png',
            title: 'Product URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: url,
            canBlank: false,
            set: setURL
        },
        retail: {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Retail',
            pattern: /^(\d+)$/g,
            value: retail,
            canBlank: false,
            set: setRetail
        },
        resell: {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Resell',
            pattern: /^(\d+)$/g,
            value: resell,
            canBlank: false,
            set: setResell
        },
        releaseDate: {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: true,
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 1,
            className: 'release-date-1',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate
        },
        releaseTime: {
            icon: '/imgs/icons/clock.png',
            title: 'Release Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 2,
            className: 'release-time-1',
            tooltip: 'hh.mm',
            set: setReleaseTime
        },
        releaseDate2: {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            className: 'release-date-2',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate
        },
        startReleaseTime: {
            icon: '/imgs/icons/clock.png',
            title: 'Start Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: startReleaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 1,
            className: 'start-time',
            tooltip: 'hh.mm',
            set: setStartReleaseTime
        },
        endReleaseTime: {
            icon: '/imgs/icons/clock.png',
            title: 'End Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: endReleaseTime,
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 2,
            className: 'end-time',
            tooltip: 'hh.mm',
            set: setEndReleaseTime
        },
        img: {
            icon: '/imgs/icons/img.png',
            title: 'Image URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: img,
            canBlank: false,
            set: setImgBuffer
        },
        desc: {
            icon: '/imgs/icons/pencil.png',
            title: 'Description',
            pattern: /^(.|[\n\r\t])+$/g,
            value: desc,
            canBlank: false,
            set: setDesc,
            multiline: true
        },
        sellingTime: {
            icon: '/imgs/icons/clock.png',
            title: 'Average Selling Time',
            pattern: /^(.+)$/g,
            value: sellingTime,
            canBlank: false,
            set: setSellingTime
        },
        returns: {
            icon: '/imgs/icons/returns.png',
            title: 'Returns',
            pattern: /^(.+)$/g,
            value: returns,
            canBlank: false,
            set: setReturns
        },
        shipping: {
            icon: '/imgs/icons/lorry.png',
            title: 'Shipping Cost (£)',
            pattern: /^(\d+)$/g,
            value: shipping,
            canBlank: false,
            set: setShipping
        },
        soldListing: {
            icon: '/imgs/icons/link-black.png',
            title: 'Ebay Sold Listing URL',
            pattern: /^(https?:\/\/(www\.)?ebay\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: soldListing,
            canBlank: false,
            set: setSoldListing
        },
        whereToSell: {
            icon: '/imgs/icons/store.png',
            title: 'Where To Sell',
            pattern: /^(.+)$/g,
            value: whereToSell,
            canBlank: true,
            set: setWhereToSell
        },
        msgLink: {
            icon: '/imgs/icons/link-black.png',
            title: 'Message Link',
            pattern: /^(https?:\/\/discord\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: msgLink,
            canBlank: true,
            set: setMsgLink
        },
        discordImg: {
            icon: '/imgs/icons/discord.png',
            title: 'Discord Image (URL)',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: discordImg,
            canBlank: false,
            set: setDiscordImg
        },
        category: {
            // icon: '/imgs/icons/discord.png',
            title: 'Category',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: discordImg,
            canBlank: false,
            set: setCategory
        },
    }).current

    const updateNewBuy = async () => {

        let alertDesc = ''
        let res = await IC.allValid()
        let time = ''

        // Parse release time
        try {
            switch (releaseType.toLocaleLowerCase()) {
                case 'default':
                    time = releaseDate + ' ' + releaseTime
                    break
                case 'range':
                    time = releaseDate + ' ' + startReleaseTime + ' ' + endReleaseTime
                    break
            }
        }
        catch (e) {
            log(e)
        }

        if (res.valid) {
            try {
                await api.post('update_newbuy', {
                    "jwttoken": token ,
                    "product_name": name,
                    "url": url,
                    "price": retail,
                    "resell_value": resell,
                    "release_time": time,
                    "release_type": releaseType,
                    "image_url":  img,
                    "discord_image": discordImg,
                    "return_policy": !returns ? "" : returns,
                    "description": desc,
                    "where_to_sell": whereToSell,
                    "ebay_listings": soldListing,
                    "shipping_cost":  shipping,
                    "selling_time": sellingTime,
                    "category": category,
                    "newbuy_id": props.item.id,
                    "message_link": msgLink
                }).then((res) => {
                    console.log(res.data)
                    if(res.data.status == "success"){
                        alertDesc = "New buy updated!"
                    }
                    else {
                        alertDesc = res.data.error
                    }
                })
            }
            catch (e) {
                alertDesc = 'Failed to add new buy.'
                console.log(e)
            }
        }
        else {
            alertDesc = res.msg
            console.log(IC.getInputs())
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        props.setOverlay(0, alert)
    }

    // const [ open, setOpen ] = useState(false)
    const categories = useRef([
        { label: 'Main', value: 'main' },
        { label: 'Lifetime', value: 'lifetime' },
        { label: 'Flip Test', value: 'fliptest' },
        { label: 'Developer Testing', value: 'devtest' },
    ]).current

    const releaseTypes = useRef([
        { label: 'Default', value: 'Default' },
        { label: 'Range', value: 'Range' },
        { label: 'Out Now', value: 'Out Now' },
        { label: 'Coming Soon', value: 'Coming Soon' },
        { label: 'Previously Released', value: 'Previously Released' },
        { label: 'Expired', value: 'Expired' },
    ]).current

    return (
            <div className={ styles.wrap }>
                <div className={ styles.topWrap }>
                    <h1
                        style={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            opacity: '.9'
                        }}
                    >Edit New Buy</h1>
                    <div className={ styles.imgOuterWrap }>
                        <div className={ styles.imgWrap }>
                            <img src={ img }/>
                        </div>
                        <TextTitle
                            title={ name }
                            setFunc={ setName }
                        />
                    </div>
                </div>
                <div className={ styles.bottomWrap }>
                    {
                        freshLink && 
                        <TextField2
                            changeFunc={ field.msgLink.set }
                            valid={ valid[field.msgLink.title] }
                            placeholder={ msgLink }
                            label={ field.msgLink.title }
                            icon={ field.msgLink.icon }
                            onFocus={ () => setCurrentField(field.msgLink.title) }
                            big={ field.msgLink.multiline ? true : false }
                        />
                    }
                    <DropDown
                        items={ categories }
                        setValue={ setCategory }
                        icon='/imgs/icons/file.png'
                        placeholder={ category }
                        className={ styles.drop1 }
                    />
                    <DropDown
                        items={ releaseTypes }
                        setValue={ setReleaseType }
                        icon='/imgs/icons/file.png'
                        placeholder={ releaseType }
                    />
                    <TextField2
                        changeFunc={ field.url.set }
                        valid={ valid[field.url.title] }
                        label={ field.url.title }
                        placeholder={ url }
                        onFocus={ () => setCurrentField(field.url.title) }
                        big={ field.url.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.retail.set }
                        valid={ valid[field.retail.title] }
                        label={ field.retail.title }
                        placeholder={ retail }
                        onFocus={ () => setCurrentField(field.retail.title) }
                        big={ field.retail.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.resell.set }
                        valid={ valid[field.resell.title] }
                        label={ field.resell.title }
                        placeholder={ resell }
                        onFocus={ () => setCurrentField(field.resell.title) }
                        big={ field.resell.multiline ? true : false }
                    />
                    <TimeSection
                        releaseType={ releaseType ? releaseType : 'out now' }
                        valid={ valid }
                        setCurrentField={ setCurrentField }
                        field={{ 
                            releaseDate: field.releaseDate,
                            releaseTime: field.releaseTime,
                            releaseDate2: field.releaseDate2,
                            startReleaseTime: field.startReleaseTime,
                            endReleaseTime: field.endReleaseTime
                        }}
                        value={{
                            releaseDate: releaseDate,
                            releaseTime: releaseTime,
                            releaseDate2: releaseDate,
                            startReleaseTime: startReleaseTime,
                            endReleaseTime: endReleaseTime
                        }}
                    />
                    <TextField2
                        changeFunc={ field.img.set }
                        valid={ valid[field.img.title] }
                        label={ field.img.title }
                        placeholder={ img }
                        onFocus={ () => setCurrentField(field.img.title) }
                        big={ field.img.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.discordImg.set }
                        valid={ valid[field.discordImg.title] }
                        label={ field.discordImg.title }
                        placeholder={ discordImg }
                        onFocus={ () => setCurrentField(field.discordImg.title) }
                        big={ field.discordImg.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.desc.set }
                        valid={ valid[field.desc.title] }
                        label={ field.desc.title }
                        placeholder={ desc }
                        value={ desc }
                        onFocus={ () => setCurrentField(field.desc.title) }
                        big={ field.desc.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.sellingTime.set }
                        valid={ valid[field.sellingTime.title] }
                        label={ field.sellingTime.title }
                        placeholder={ sellingTime }
                        onFocus={ () => setCurrentField(field.sellingTime.title) }
                        big={ field.sellingTime.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.returns.set }
                        valid={ valid[field.returns.title] }
                        label={ field.returns.title }
                        placeholder={ returns }
                        onFocus={ () => setCurrentField(field.returns.title) }
                        big={ field.returns.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.shipping.set }
                        valid={ valid[field.shipping.title] }
                        label={ field.shipping.title }
                        placeholder={ shipping }
                        onFocus={ () => setCurrentField(field.shipping.title) }
                        big={ field.shipping.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.soldListing.set }
                        valid={ valid[field.soldListing.title] }
                        label={ field.soldListing.title }
                        placeholder={ soldListing }
                        onFocus={ () => setCurrentField(field.soldListing.title) }
                        big={ field.soldListing.multiline ? true : false }
                    />
                    <TextField2
                        changeFunc={ field.whereToSell.set }
                        valid={ valid[field.whereToSell.title] }
                        label={ field.whereToSell.title }
                        placeholder={ whereToSell }
                        onFocus={ () => setCurrentField(field.whereToSell.title) }
                        big={ field.whereToSell.multiline ? true : false }
                    />
                </div>
                <Button
                    label="Update New Buy"
                    onClick={ updateNewBuy }
                />
            </div>
    )
}