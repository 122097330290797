import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/layouts/Page'
import Modal from '../components/Modal'
import { UpdateNewBuy } from '../components/slideUps'
import { pinger } from '../components/slideUps/pinger'
import StatCount from '../components/StatCount'
import Table from '../components/Table'
import { rgx } from '../constants'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import { api } from '../funcs/apiCall'
import { crud, CrudControl } from '../lib/crud'
import datetime from '../lib/time'
import { actions } from '../redux/actions'
import styles from '../styles/appfeed.module.scss'

export default function NewBuys({ setOverlay }) {

    const dispatch = useDispatch()
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const refresh = useSelector( state => state.refresh.run )
    const refreshPage = useSelector( state => state.refresh.page )
    
    const [alert, setAlert] = useState(false)
    const [slide, setSlide] = useState(1)
    const [item, setItem] = useState()
    const [searchStr, setSearchStr] = useState('')
    
    // Table data
    
    const [ headers, setHeaders ] = useState([])
    const [ newBuys, setNewBuys ] = useState([])

    // Field data
    
    const [ category, setCategory ] = useState('')
    const [ releaseType, setReleaseType ] = useState('')
    const [ name, setName ] = useState('')
    const [ url, setURL ] = useState('')
    const [ retail, setRetail ] = useState('')
    const [ resell, setResell ] = useState('')
    const [ releaseDate, setReleaseDate ] = useState('')
    const [ releaseTime, setReleaseTime ] = useState('')
    const [ startReleaseTime, setStartReleaseTime ] = useState('')
    const [ endReleaseTime, setEndReleaseTime ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ discordImg, setDiscordImg ] = useState('')
    const [ desc, setDesc ] = useState('')
    const [ sellingTime, setSellingTime ] = useState('')
    const [ returns, setReturns ] = useState('')
    const [ shipping, setShipping ] = useState('')
    const [ soldListing, setSoldListing ] = useState('')
    const [ whereToSell, setWhereToSell ] = useState('')
    const [ categorySet, setCategorySet ] = useState(false)
    const [ releaseTypeSet, setReleaseTypeSet ] = useState(false)
    
    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const categoryItems = [
        { label: 'Main', value: 'main' },
        { label: 'Lifetime', value: 'lifetime' },
        { label: 'Flip Test', value: 'fliptest' },
        { label: 'Developer Testing', value: 'devtest' },
    ]
    
    const releaseTypeItems = [
        { label: 'Default', value: 'Default' },
        { label: 'Range', value: 'Range' },
        { label: 'Out Now', value: 'Out Now' },
        { label: 'Coming Soon', value: 'Coming Soon' },
        { label: 'Previously Released', value: 'Previously Released' },
    ]

    const [ activeField, setActiveField ] = useState([false, false, false, false])

    // Activates and disbles relevant fields when the release type is updated
    const setDateFields = () => {
        if (releaseType !== '' ) {
            setReleaseTypeSet(true)
        }

        /*
        1 - Release Date
        2 - Release Time
        3 - Start time
        4 - End time
        */
       
        switch (releaseType) {
            case 'Out Now':
                setActiveField([false, false, false, false])
                break;
            case 'Default':
                setActiveField([true, true, false, false])
                break;
            case 'Range':
                setActiveField([true, false, true, true])
                break;
            case 'Coming Soon':
                setActiveField([false, false, false, false])
                break;
            case 'Previously Released':
                setActiveField([false, false, false, false])
        }
    }

    useEffect(() => {
        setDateFields()
    }, [ releaseType ])
    
    const fields = [
        {
            type: 'dropdown',
            title: "category",
            items: categoryItems,
            placeholder: 'Category',
            pattern: rgx.article,
            canBlank: false,
            value: category,
            set: setCategory
        },
        {
            type: 'dropdown',
            title: "release type",
            items: releaseTypeItems,
            placeholder: 'Release Type',
            pattern: rgx.article,
            canBlank: false,
            value: releaseType,
            set: setReleaseType
        },
        {
            icon: '/imgs/icons/person.png',
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Product URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: url,
            canBlank: true,
            set: setURL
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Retail',
            pattern: rgx.price,
            value: retail,
            canBlank: false,
            className: 'retail',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setRetail
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Resell',
            pattern: rgx.price,
            value: resell,
            canBlank: false,
            className: 'resell',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setResell
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: !activeField[0],
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 1,
            className: 'release-date-1',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate,
            visible: activeField[0]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseTime,
            canBlank: !activeField[1],
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 2,
            className: 'release-time-1',
            tooltip: 'hh.mm',
            set: setReleaseTime,
            visible: activeField[1]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Start Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: startReleaseTime,
            canBlank: !activeField[2],
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 1,
            className: 'start-time',
            tooltip: 'hh.mm',
            set: setStartReleaseTime,
            visible: activeField[2]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'End Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: !activeField[3],
            canBlank: true,
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 2,
            className: 'end-time',
            tooltip: 'hh.mm',
            set: setEndReleaseTime,
            visible: activeField[3]
        },
        {
            icon: '/imgs/icons/img.png',
            title: 'Image URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: img,
            canBlank: false,
            set: setImgBuffer
        }, 
        {
            icon: '/imgs/icons/discord.png',
            title: 'Discord Image (URL)',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: discordImg,
            canBlank: false,
            set: setDiscordImg
        }, 
        {
            icon: '/imgs/icons/pencil.png',
            title: 'Description',
            pattern: /^(.|[\n\r\t])+$/g,
            value: desc,
            canBlank: false,
            set: setDesc,
            multiline: true
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Average Selling Time',
            pattern: /^(.+)$/g,
            value: sellingTime,
            canBlank: false,
            set: setSellingTime
        },
        {
            icon: '/imgs/icons/returns.png',
            title: 'Returns',
            pattern: /^(.+)$/g,
            value: returns,
            canBlank: false,
            set: setReturns
        },
        {
            icon: '/imgs/icons/lorry.png',
            title: 'Shipping Cost (£)',
            pattern: /^(\d+)$/g,
            value: shipping,
            canBlank: false,
            set: setShipping
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Ebay Sold Listing URL',
            pattern: /^((tbc|TBC)|(https?:\/\/(www\.)?ebay\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)))$/g,
            value: soldListing,
            canBlank: false,
            set: setSoldListing
        },
        {
            icon: '/imgs/icons/store.png',
            title: 'Where To Sell',
            pattern: /^(.+)$/g,
            value: whereToSell,
            canBlank: true,
            set: setWhereToSell
        }
    ]


    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current


    const newBuyProps = {
        "product_name": name,
        "url": url,
        "price": retail,
        "resell_value": resell,
        "release_time": ['Live', 'Random'].includes(releaseType) ? '' : ( releaseType == 'Default' ? releaseDate + ' ' + releaseTime : releaseDate + ' ' + startReleaseTime + ' ' + endReleaseTime),
        "release_type": releaseType,
        "image_url":  img,
        "discord_image": discordImg,
        "return_policy": returns,
        "description": desc,
        "where_to_sell": whereToSell,
        "ebay_listings": soldListing,
        "shipping_cost":  shipping,
        "selling_time": sellingTime,
        "category": category,
        "user_id": userID
    }

    const loadNewBuys = async () => {

        await api.get2('newbuy_history', { jwttoken: token }).then((res) => {

            let headData = []

            if (!isMobile()) {
                headData = [
                    { title: 'Image', field: 'image'},
                    { title: 'Name', field: 'name'},
                    { title: 'Category',  field: 'category'},
                    { title: 'Release', field: 'release'},
                    { title: 'Uploaded', field: 'uploaded'},
                    { title: 'Publisher', field: 'publisher'},
                ]
            }
            else {
                headData = [
                    { title: 'Image', field: 'image'},
                    { title: 'Name', field: 'name'},
                ]
            }

            let parsed = res.data.map((record) => {
                
                // let release = datetime.getStandard(record.release_time)
                let release = datetime.getStandard(record.releaseTime)
                let created = datetime.getStandard(record.upload_time)

                // if (release.datetime === '') {
                //     release.datetime = record.release_type
                // }

                // console.log(record.release_time)

                // console.log(record)
                return {
                    id: record.id,
                    image: record.image_url,
                    category: record.category,
                    name: record.product_name,
                    uploaded: created.date,
                    release: release.datetime,
                    publisher: record.uploaded_by,
                    url: record.url
                //     url: record.store_link
                }
            })
            
            setHeaders(headData)
            setNewBuys(parsed)
        }).catch((e) => {
            console.log('ERROR: ', e)
            setNewBuys([])
        })
    }

    const crudFunc = {
        create: <pinger.newBuy setOverlay={ setOverlay } load={ loadNewBuys }/>,
        read: (record) => window.open(record.url, '_blank'),
        update: (record) =>  {
            setOverlay(2, <UpdateNewBuy setOverlay={ setOverlay } item={ newBuys.filter((item) => item.id == record.id)[0] }/>)
        },
        delete: (itemList) => crudControl.deleteNewBuy(itemList, loadNewBuys),
    }

    useEffect(() => {
        loadNewBuys()
    }, [])

    useEffect(() => {
        loadNewBuys()

        if ((refreshPage == 'new buy') && refresh) {
            loadNewBuys()
            dispatch(actions.setRefresh(false))
        }
    }, [ refresh ])

    return (
        <>
            <Page
                title='New Buys'
                current={1}
                setOverlay={setOverlay}
                setSearchStr={ setSearchStr }
            >
                {
                    alert !== false &&
                    <Modal
                        text={ alert }
                        set={ setAlert }
                    />
                }
                <Table
                    headers={ headers }
                    data={ newBuys }
                    crudFunc={ crudFunc }
                    btnText="New Buy"
                    filterField="category"
                    searchStr={ searchStr }
                    setOverlay={ setOverlay }
                />
            </Page>
        </>
    )
}