import { useState, useEffect, useRef } from 'react'
import NavBar from '../NavBar'
import styles from '../../styles/layouts/page.module.scss'
import Alert from '../Alert'
import DropDown from '../DropDown'
import { useNavigate } from 'react-router-dom'
import { api } from '../../funcs/apiCall'
import Button from '../Button'
import { isMobile } from '../../funcs'
import { useSelector } from 'react-redux'
import TextField from '../TextField'

const LoadingView = () => (
    <div className={ styles.loadingView }>
        <img src='/imgs/logos/loading_screen.gif' />
    </div>
)

export default function Page({ children, current, title, blur, setOverlay, dropDown, history, setSearchStr }) {

    const navigate = useNavigate()
    const token = useRef( useSelector( state => state.user.token ) ).current
    const name = useRef( useSelector( state => state.user.name ) ).current
    const [ loading, setLoading ] = useState(false)

    useEffect( async () => {

        if (token == '') {
            navigate('/Login')
        }
        
        await api.get2('checkauth', { "jwttoken": token }).then((res) => {
            try {
                if (res.data.admin == "true" || res.data.admin == true) {
                    
                    if (window.location.pathname === '/analytics') {
                        if (!res.data.analytics) {
                            navigate('/Restocks')
                        }
                    }

                    setLoading(false)
                } else {
                    navigate('/Login')
                }
            }
            catch (e) {
                navigate('/Login')
                console.log('ERROR: ', e)

            }
        })
    }, [])
    
    return (
        <>
            {
                loading &&
                <LoadingView />
            }
            {
                !loading &&
                <div
                    className={`${ styles.wrap } ${ blur ? 'blurLock blur' : '' }`}
                >
                    <NavBar
                        current={ current }
                        setOverlay={ setOverlay }
                    />
                    <section className={styles.right}>
                        <img className={styles.bgImg} src='/imgs/bgs/blue-bg.svg'/>
                        <header className={ styles.header }>
                            <div className={ styles.titleWrap }>
                                <p className={ styles.title }>{ title }</p>
                            </div>
                            <span className={ styles.rightWrap }>
                                {/* <p className={ styles.name }>{ name }</p> */}
                                <TextField
                                    class={ styles.search }
                                    key="search-box"
                                    changeFunc={ setSearchStr !== undefined ? setSearchStr : () => {} }
                                    valid={ true }
                                    placeholder="Search"
                                    // type="custom"
                                    // icon={ field.icon }
                                    // onFocus={ () => fieldAttr.setField(field.title) }
                                />
                                <span className={ styles.userBtn }>
                                    <img src='/imgs/bgs/ozzy.png' />
                                </span>
                            </span>
                        </header>
                        <main className={ styles.main }>
                            { children }
                        </main>
                    </section>
                </div>
            }
        </>
    )
}