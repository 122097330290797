import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CrudControl } from '../../../lib/crud'
import { rgx } from '../../../constants'
import { Modal } from '../..'
import { actions } from '../../../redux/actions'

export default function ScheduledNotiPinger({ load, setOverlay }) {

    const dispatch = useDispatch()

    const token = useRef( useSelector( state => state.user.token ) ).current
    const [alert, setAlert] = useState(false)

    // Field data

    const [ category, setCategory ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ content, setContent ] = useState('')
    const [ url, setURL ] = useState('')
    const [ version, setVersion ] = useState('')
    const [ date, setDate ] = useState('')
    const [ time, setTime ] = useState('')

    const categories = [
        { label: 'News', value: 'news' },
        { label: 'Misc', value: 'misc' },
        { label:  'Sneakers', value: 'sneakers'},
        { label:  'Tickets', value: 'tickets'},
        { label:  'Lifetime', value: 'lifetime'},
        { label: 'Inactive', value: 'inactive' },
        { label:  'Developer', value: 'developer'},
        { label: 'Staff', value: 'staff' }
    ]

    const fields = [
        {
            type: 'dropdown',
            title: "category",
            items: categories,
            placeholder: 'Category',
            pattern: rgx.article,
            canBlank: false,
            value: category,
            set: setCategory
        },
        {
            title: 'Title',
            pattern: rgx.name,
            value: title,
            canBlank: false,
            set: setTitle,
            icon: '/imgs/icons/img.png'
        },
        {
            title: 'URL',
            pattern: rgx.url,
            value: url,
            canBlank: true,
            set: setURL,
            icon: '/imgs/icons/link-black.png',
            className: 'url',
            tooltip: 'https://website.com'
        },
        {
            title: 'Date',
            pattern: rgx.date,
            value: date,
            canBlank: false,
            inline: true,
            colCount: 1,
            set: setDate,
            icon: '/imgs/icons/clock.png',
            tooltip: 'dd.mm.yy',
            className: 'date'
        },
        {
            title: 'Time',
            pattern: rgx.time,
            value: time,
            canBlank: false,
            inline: true,
            colCount: 2,
            set: setTime,
            icon: '/imgs/icons/clock.png',
            tooltip: 'hh.mm',
            className: 'time'
        },
        {
            title: 'Content',
            pattern: rgx.article,
            value: content,
            canBlank: false,
            multiline: true,
            set: setContent,
            big: true,
            icon: '/imgs/icons/book-black.png'
        }
    ]

    // CRUD 

    const crudControl = useRef( new CrudControl(token) ).current

    const notiProps = {
        'noti_type': category,
        'title': title,
        'content': content,
        'ad_info': url,
        'version': version,
        'date': date,
        'time': time
    }

    const setup = () => {
        dispatch(actions.setRefreshPage('noti schedule'))
    }

    const triggerRefresh = () => {
        dispatch(actions.setRefresh(true))
    }

    useEffect(() => {
        setup()
    }, [])
    
    return (
        <>
            <Modal
                inputs={ fields }
                inputsPerPage={ 4 }
                title={ "Schedule Notification" }
                set={ (l) => {} }
                func={ async () => await crudControl.createNoti(notiProps, triggerRefresh) }
                setAlert={ setAlert }
                setOverlay={ setOverlay  }
            />
        </>
    )
}