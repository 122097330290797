import { useState, useRef, useEffect, createElement } from 'react'
import { rgx } from '../constants'
import { InputCenter } from '../funcs/Valid'
import styles from '../styles/components/modal.module.scss'
import Button from './Button'
import DropDown from './DropDown'
import TextField from './TextField'

const log = console.log

const Form = ({ inputsPerPage, fields, setBodyStyle, currentPage, setPageCount, fieldAttr }) => {

    const [ paneCount, setPaneCount ] = useState(Math.ceil(fields.length / inputsPerPage))
    const [ pages, setPages ] = useState([])
    const formHeight = (2.7 * inputsPerPage) + (0.9 * (inputsPerPage - 1)) + 'rem'
    const rightMargin = 4
    const pageWidth = 25.6 + rightMargin
    
    const inlineStyles = {
        body: {
            height: formHeight,
        },
        formWrap: {
            height: formHeight
        },
        form: {
            height: formHeight,
            width: (pageWidth * paneCount) - rightMargin + 'rem',
            left: currentPage * pageWidth * -1 + 'rem'
        },
    }

    useEffect(() => {

        setBodyStyle(inlineStyles.body)
        fillPages()
    }, [])

    useEffect(() => {
        fillPages()
    }, [ fields ])

    // Allocates fields to respective pages
    const fillPages = () => {
        let tmp = [[]]
        let count = 0
        let offset = 0

        const isBig = (count) => {
            let current = fields[count]
            let prev = fields[count - 1]
            let jump = false
            
            if ((current !== undefined ) && current.multiline) {
                jump = true

                if (count !== fields.length -1) {
                    offset++
                }
            }

            if ((prev !== undefined) && prev.multiline) {
                jump = true
            }

            return jump
        }
 
        for(let i=0; i<fields.length; i++) {
            if ((i - offset) % inputsPerPage == 0 && (i !== 0) || isBig(i)) {
                // if (i !== fields.length -1) {
                    count++
                    tmp.push([])
                // }
            }
            
            tmp[count].push(fields[i])
        }

        setPages(tmp)
        setPageCount(tmp.length + offset)
        setPaneCount(tmp.length + offset)
    }

    return (
        <div className={ styles.formWrap } style={ inlineStyles.formWrap }>
            <div className={ styles.form } style={ inlineStyles.form }>
                {
                    pages.map((page, index) => (
                        <span
                            className={ styles.page }
                            key={`page-${ index }`}
                        >
                            {
                                page.map((field, index) => {

                                    let { visible, type } = field
                                    
                                    if (visible == true || visible == undefined) {
                                        if (type == 'dropdown') {
                                            return (
                                                <DropDown
                                                    key={`field-${ index }`}
                                                    items={ field.items }
                                                    setValue={ field.set }
                                                    icon={ field.icon ? field.icon : '/imgs/icons/file.png' }
                                                    placeholder={ field.placeholder }
                                                    onClick={ () => fieldAttr.setField(field.title) }
                                                />
                                            )
                                        }

                                        if (!type || type == 'text') {
                                            return (
                                                <TextField
                                                    key={`field-${ index }`}
                                                    changeFunc={ field.set }
                                                    valid={ fieldAttr.valid[field.title] }
                                                    placeholder={ field.title }
                                                    multiline={ field.multiline ? true : false }
                                                    // type="custom"
                                                    icon={ field.icon }
                                                    onFocus={ () => fieldAttr.setField(field.title) }
                                                />
                                            )
                                        }
                                    }
                                })
                            }
                        </span>
                    ))
                }
            </div>
        </div>
    )
}

const PageNav = ({ data }) => {

    const [ pages, setPages ] = useState([])

    const loadPages = () => {
        let tmp = []

        for (let i=0; i<data.count; i++) {
            tmp.push(data.current == i )
        }

        setPages(tmp)
    }

    useEffect(() => {
        loadPages()
    }, [ data ])

    return (
        <span className={ styles.pageNav }>
            {
                pages.map((current, index) => (
                    <span className={`${ styles.pageDot } ${ current ? styles.currentPage : '' }`} key={`modal-nav-${ index }`}></span>
                ))
            }
        </span>
    )
}

const ModalFoot = ({ page, mode, btns, close, nav, func, IC }) => {


    // The maximum number of accepted buttons is two
    const btnList = btns ? btnList.slice(0,2) : []
    const firstPage = page.current !== 0
    const lastPage = page.current == page.count - 1
    const [ activeBtn, setActiveBtn ] = useState(true)

    const finish = async () => {

        const run = async () => {
            try {
                let { valid, msg } = await IC.allValid()
    
                if (valid) {
                    let res = await func.run()
                    
                    func.alert(res.msg)
                }
                else {
                    func.alert(msg)
                }
            } catch (e) { 
                console.error(e)
            } 
        }

        setActiveBtn(false)
        await run()
        setActiveBtn(true)
    }

    const yesHandler = () => {
        func.run()
        close()
    }

    return (
        <div className={ styles.footer }>
            {
                mode == 'yesno' &&
                <div className={ styles.gridBtn }>
                    <Button
                        label="No"
                        onClick={ close }
                    />
                    <Button
                        label="Yes"
                        onClick={ yesHandler }
                    />
                </div>
            }
            {
                !btns && mode == 'text' &&
                <div className={ styles.centerBtn }>
                    <Button
                        label="Ok"
                        fit={ true }
                        onClick={ close }
                    />
                </div>
            }
            {
                !btns && mode !== 'form' &&
                <div className={ styles.gridBtn }>
                    {
                        btnList.map((btn) => (
                            <Button
                                label={ btn.label }
                                onClick={ () => { 
                                    btn.func()
                                    close()
                                }}
                            />
                        ))
                    }
                </div>
            }
            {
                !btns && mode == 'form' &&
                <div className={ styles.magicWrap }>
                    <PageNav data={ page }/>
                    <div className={ styles.btnWrap }>
                        {
                            firstPage &&
                            <Button
                                label="Back"
                                onClick={ nav.back }
                                class={ styles.formBtn }
                            />
                        }
                        <Button
                            label={ lastPage ? "Send" : "Next" }
                            onClick={ lastPage ? finish : nav.forward }
                            class={ styles.formBtn }
                            disabled={ !activeBtn }
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default function Modal(props) {

    let { ceil, random } = Math

    const overlayTime = 50
    const wrapTime = 300

    const [ mode, setMode ] = useState('')
    const [ currentPage, setCurrentPage ] = useState(0)
    const [ pageCount, setPageCount ] = useState(0)
    const [ bodyStyle, setBodyStyle ] = useState({})
    const [ id ] = useState(ceil(random() * 100)) 
    const [ shutdown, setShutdown ] = useState(false)

    // Validation specifications

    const IC = useRef( new InputCenter() ).current
    const [ valid, setValid ] = useState({})
    const [ currentField, setCurrentField ] = useState('')
    const nameDict = props.inputs ? props.inputs.map((item) => ({ title: item.title, value: item.value })) : []

    const setAlert = (msg) => {
        props.setOverlay(0, {
            desc: msg,
            btns: [
                    {
                        label: 'OK',
                        returnValue: false
                    },
            ],
            setFunc: (l) => {}
        })
    }

    const setupValidation = () => {
        if (props.inputs) {
            let tmp = {}
    
            props.inputs.map((field) => {
                IC.addInput(field)
                tmp[field.title] = true
            })
    
            setValid(tmp)
        }
    }

    const validate = (field) => {

        
        let tmp = {
            ...valid
        }
        
        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        let found = nameDict.filter((field) => field.title === currentField)

        if (found.length > 0) {
            IC.setValue(currentField, found[0].value)
            validate(currentField)
        }
    }, [ props.inputs ])

    useEffect(() => {
        if (shutdown) {
            props.setOverlay()
        }
    }, [ shutdown ])

    // Navigates to next page
    const nextPage = () => {
        setCurrentPage((currentPage < pageCount - 1) ? currentPage + 1 : currentPage)
    }

    // Navigates to last page
    const lastPage = () => {
        setCurrentPage((currentPage !== 0) ? currentPage - 1 : 0 )
    }
    
    // Closes modal (and allows time for animations to occur)
    const close = () => {

        const wrap = document.getElementById(`wrap-${ id }`)
        
        setTimeout(() => {

            try {
                props.setOverlay(0, {
                    desc: 'Are you sure you want to close this screen?',
                    btns: [
                        {
                            label: 'No',
                            returnValue: false
                        },
                        {
                            label: 'Yes',
                            returnValue: true
                        },
                    ],
                    setFunc: setShutdown
                })
            }
            catch (e) {
                console.error(e)
            }
        }, wrapTime)
    }

    // Opens the modal (allowing time for animations)
    const open = () => {
        const overlay = document.getElementById(`overlay-${ id }`)
        const wrap = document.getElementById(`wrap-${ id }`)
        
        setTimeout(() => {
            overlay.classList.add(styles.reveal)

            setTimeout(() => {
                wrap.classList.add(styles.reveal)
            }, wrapTime)
        }, overlayTime)
    }

    // Sets the forms specifications
    const setFormSpecs = () => {

        if (props.text) {
            setMode('text')
        }

        if (props.yesNo == true) {
            setMode('yesno')
        }

        if (props.inputs) {

            if (props.rightSlide == true) {
                setMode('rightSlide')
            }
            else {
                setMode('form')
            }
        }

        if (props.children) {
            if (props.foot) {
                setMode('semi-auto')
            }
            else {
                setMode('manual')
            }
        }
    }

    useEffect(() => {
        open()
        setFormSpecs()
        setupValidation()
    }, [])

    return (
        <div
            className={`${ styles.overlay }`}
            id={`overlay-${ id }`}
        >
            <div
                className={ styles.wrap }
                id={`wrap-${ id }`}
            >
                <div className={ styles.header }>
                    <h2>{ props.title && props.title }</h2>
                    <button
                        onClick={ close }
                    >
                        <img src='/imgs/icons/close.svg' />
                    </button>
                </div>
                <div className={ styles.body } style={ bodyStyle }>
                    {
                        mode == 'text' || mode == 'yesno' &&
                        props.text
                    }
                    {
                        mode == 'form' &&
                        <Form
                            fields={ props.inputs }
                            inputsPerPage={ props.inputsPerPage }
                            setBodyStyle={ setBodyStyle }
                            currentPage={ currentPage }
                            setPageCount={ setPageCount }
                            fieldAttr={{
                                valid,
                                setField: setCurrentField}}
                        />
                    }
                    {
                        ['auto', 'semi-auto', 'manual'].includes(mode) &&
                        props.children
                    }
                </div>
                <ModalFoot
                    page={{
                        current: currentPage,
                        count: pageCount
                    }}
                    mode={ mode }
                    // btns={ [
                    //     { label: 'Cancel', func: () => console.log('cancelled') },
                    //     { label: 'Ok', func: () => console.log('cancelled') }
                    // ] }
                    nav={{
                        back: lastPage,
                        forward: nextPage
                    }}
                    close={ close }
                    func={{
                        run: props.func,
                        alert: setAlert
                    }}
                    IC={ IC }
                />
            </div>
        </div>
    )
}