import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAnalyticsEventTracker } from '../App'
import { Pane } from '../components'
import DropDown from '../components/DropDown'
import Page from '../components/layouts/Page'
import StatCount from '../components/StatCount'
import Table from '../components/Table'
import { api } from '../funcs/apiCall'
import styles from '../styles/analytics.module.scss'
import { helpers } from '../constants'

const SubSection = ({ title, children }) => {
 
    return (
        <section
            className={ styles.section }
        >
            <div className={ styles.titleWrap }>
                <h2 className={ styles.title }>{ title }</h2>
            </div>
            { children }
        </section>
    )
}

export default function AnalyticsSeller({ setOverlay }) {

    const [ stats, setStats ] = useState({})
    const [ timeFrame, setTimeFrame ] = useState(1)
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    const [ items, setItems ] = useState([
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Today', value: 1 },
        { label: '7 Days', value: 7 }
    ])

    const loadStats = async () => {
        // add analytic seller pages to specific user ids
        const allowedUserIds = helpers.analyticSellerUserIds;
        if (allowedUserIds.indexOf(userID) == -1) {
           return;
        }

        // change to new api
        await api.post('signup_data_sc', { date_range: timeFrame, jwttoken: token }).then((res) => {

            if (res.data.status === 'success') {
                console.log(res.data)
                setStats(res.data)
            }
        })
    }



    useEffect(() => {
        loadStats()
    }, [ timeFrame ])

    useEffect(() => {
        loadStats()
    }, [])

    return (
        <>
            <Page
                title="Analytics Seller"
                current={ 4 }
                dropDown={{
                    items,
                    setValue: setTimeFrame,
                    icon: '/imgs/icons/clock.png',
                    placeholder: 'Today',
                    white: true,
                }}
                setOverlay={ setOverlay }
            >
                <div className={ styles.dropDownWrap }>
                    <DropDown
                        // key={`field-${ index }`}
                        items={ items }
                        setValue={ setTimeFrame }
                        // icon={ '/imgs/icons/clock.png' }
                        placeholder={ 'Today' }
                    />
                </div>
                <div className={ styles.pageWrap }>
                    <section className='stat3'>
                        <StatCount
                            label='Total Sign Ups'
                            value={ stats.TotalSignups }
                            direction='up'
                        />
                        <StatCount
                            label='Apple Sign Ups'
                            value={ stats.AppleSignups }
                            direction='up'
                        />
                        <StatCount
                            label='Word Press Sign Ups'
                            value={ stats.WpSignups }
                            direction='up'
                        />
                    </section>
                    {/* <SubSection
                        title='Notifications'
                    >
                        <section
                            className={ styles.horizontalSection }
                        >
                            <Pane
                                flex={ 2 }
                            >
                                asdf
                            </Pane>
                            <Pane
                                flex={ 3.46 }
                            >
                                asdf
                            </Pane>
                            <Table
                            />
                        </section>
                    </SubSection> */}
                </div>
            </Page>
        </>
    )
}