export const SET_NAME = 'SET_NAME'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_ID = 'SET_ID'

// Refresh
export const SET_REFRESH = 'SET_REFRESH'
export const SET_REFRESH_PAGE = 'SET_CURRENT_PAGE'

export const SET_OVERLAY_FUNC = 'SET_OVERLAY_FUNC'
export const SET_CURRENT_OVERLAY = 'SET_CURRENT_OVERLAY'
