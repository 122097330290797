import { ConnectingAirportsOutlined } from '@mui/icons-material'
import { useState, useEffect, useRef } from 'react'
import styles from '../styles/components/dropdown.module.scss'

const Item = ({ item, setValue, toggleOpen, setPlaceholder, parentOnPress }) => {

    return (
        <div className={ styles.item }
            onClick={ () => {
                setValue(item.value)
                setPlaceholder(item.label)
                toggleOpen()
        
                if (parentOnPress) {
                    parentOnPress()
                }
            }}
        >
            <p className={ styles.itemLabel }>{ item.label }</p>
        </div>
    )
}

export default function DropDown(props) {

    const [ open, setOpen ] = useState(false)
    const [ placeholder, setPlaceholder ] = useState('')

    const toggleOpen = () => {

        if (props.onClick) {
            props.onClick()
        }

        setOpen(!open)
    }

    useEffect(() => {
    
        const page = document.getElementsByTagName('body')[0]
        
        page.addEventListener('click', (e) => {
            
            let classList = [ ... e.target.classList ]
            let classStr = classList.toString()

            if (!classStr.includes('dropdown')) {
                setOpen(false)
            }
        })
    }, [])

    return (
        <div
            className={`${ styles.wrap } ${ props.white ? styles.white : '' } ${ props.className ? props.className : '' } dropdown`}
            onClick={ toggleOpen }
            style={ open ? { zIndex: 100000 } : {} }
        >
            <div className={ styles.topWrap }>
                <div className={ styles.left }>
                    {
                        props.icon &&
                        <div className={ styles.iconWrap }>
                            <img
                                src={ props.icon }
                                className={ styles.icon }
                            />
                        </div>
                    }
                    <p className={ styles.placeholder }>{ placeholder == '' ? props.placeholder.charAt(0).toUpperCase() + props.placeholder.slice(1) : placeholder }</p>
                </div>
                <img
                    src={`/imgs/icons/${ props.white ? 'arrow-dark.svg' : 'arrow_2.png' }`}
                    className={`${ styles.arrow } ${ open ? styles.rotate : '' }`}
                />
            </div>
            {
                open &&
                <div className={ styles.itemWrap }>
                    {
                        props.items.map((item, count) => (
                            <Item
                                item={ item }
                                setValue={ props.setValue }
                                toggleOpen={ toggleOpen }
                                setPlaceholder={ setPlaceholder }
                                parentOnPress={ () => {
                                    if (props.onPress) {
                                        props.onPress()
                                    }
                                } }

                                key={ count }
                            />
                        ))
                    }
                </div>
            }
        </div>
    )
}