import * as deleteOp from './delete'
import * as create from './create'
import * as read from './read'
import * as update from './update'

/**
 * This object provides a syntactically simple means of utilising CRUD operations
 */

export class CrudControl {

    constructor(jwt) {
        this.jwt = jwt
    }

    // CREATE

    createRestock = async (props, callBack = async() => {}) => await create.restock(this.jwt, props, callBack)
    createNewBuy = async (props, callBack = async() => {}) => await create.newBuy(this.jwt, props, callBack)
    createGuide = async (props, callBack = async() => {}) => await create.guide(this.jwt, props, callBack)
    createNoti = async (props, callBack = async() => {}) => await create.noti(this.jwt, props, callBack)


    // READ

    // -- all

    readRestock = async (callBack = async() => {}) => await read.restock(this.jwt, callBack)
    readNewBuy = async (callBack = async() => {}) => await read.newBuy(this.jwt, callBack)
    readGuide = async (callBack = async() => {}) => await read.guide(this.jwt, callBack)
    readNoti = async (callBack = async() => {}) => await read.noti(this.jwt, callBack)
    readProduct = async (callBack = async() => {}) => await read.product(this.jwt, callBack)

    // -- single

    getRestock = async (id) => await this.readRestock().filter((item) => item.id === id)
    getNewBuy = async (id) => await this.readNewBuy().filter((item) => item.id === id)
    getGuide = async (id) => await this.readGuide().filter((item) => item.id === id)
    getNoti = async (id) => await this.readNoti().filter((item) => item.id === id)


    // UPDATE

    updateRestock = async (props, callBack = async() => {}) => await update.restock(this.jwt, props, callBack)
    updateNewBuy = async (props, callBack = async() => {}) => await update.newBuy(this.jwt, props, callBack)
    updateGuide = async (props, callBack = async() => {}) => await update.guide(this.jwt, props, callBack)
    updateNoti = async (props, callBack = async() => {}) => await update.noti(this.jwt, props, callBack)


    // DELETE

    deleteRestock = async (name, itemList, callBack = async() => {}) => await deleteOp.restock(name, this.jwt, itemList, callBack)
    deleteNewBuy = async (itemList, callBack = async() => {}) => await deleteOp.newBuy(this.jwt, itemList, callBack)
    deleteGuide = async (itemList, callBack = async() => {}) => await deleteOp.guide(this.jwt, itemList, callBack)
    deleteNoti = async (itemList, callBack = async() => {}) => await deleteOp.noti(this.jwt, itemList, callBack)
    deleteProduct = async (itemList, callBack = async() => {}) => await deleteOp.product(this.jwt, itemList, callBack)

}