import { useRef, useEffect, useState } from 'react'
import styles from '../styles/components/alert.module.scss'
import Button from './Button'

export default function Alert({ setFunc, desc, alertBuffer, btns }) {

    const close = () => {
        alertBuffer(0, null)
    }

    const setValue = (value) => {

        // Closes 
        if (value) {
            setFunc(value)
            alertBuffer()
        }
        else {
            close()
        }
    }

    return (
        <div className={ styles.wrap }>
            <div className={ styles.top }>
                <button
                    onClick={ close }
                >
                    <img src='/imgs/icons/close.svg' />
                </button>
            </div>
            <div className={ styles.mid }>
                { desc }
            </div>
            <div className={`${ styles.bottom } ${ btns.length == 1 ? styles.unoBtnBottom : '' }`}>
                {
                    btns.map((btn) => (
                        <Button
                            label={ btn.label }
                            onClick={ () => setValue(btn.returnValue) }
                        />
                    ))
                }
            </div>
        </div>
    )
}