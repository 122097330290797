import { useEffect } from 'react'
import styles from '../styles/components/rightSlide.module.scss'


export default function RightSlide({ children, buffer }) {

    useEffect(() => {
        const wrap = document.getElementsByClassName(styles.wrap)[0]

        setTimeout(() => wrap.classList.add(styles.active), 50)
    }, [])

    return (
        <div className={`${ styles.wrap }`}>
            {
                children && children
            }
        </div>
    )
}