import { useState, useEffect, useRef } from 'react'
import styles from '../styles/components/textfield.module.scss'
import Tooltip from './Tooltip'

export default function TextField(props) {

    const [ focus, setFocus ] = useState(false)
    const [ icon, setIcon ] = useState()
    const icons = {
        email: '/imgs/icons/email.png',
        person: '/imgs/icons/person.png',
        padlock: '/imgs/icons/padlock.png'
    }
    const [ isVisible, setVisible ] = useState(true)

    const handleChange = (e) => {
        if (props.changeFunc) {
            props.changeFunc(e.target.value)
        }
    }

    useEffect(() => {
        
        switch(props.type) {
            case 'name':
                setIcon(icons.person)
                // setIconStyle(styles.iconPerson)
                break
            case 'email':
                setIcon(icons.email)
                // setIconStyle(styles.iconEmail)
                break
            case 'password':
                setVisible(false)
                setIcon(icons.padlock)
                // setIconStyle(styles.iconPadlock)
                break
            default:
                if (props.icon) {
                    setIcon(props.icon)
                }
                else {
                    setIcon(icons.person)
                }
                break
        }
    }, [])

    const focusFunc = () => {
        if (props.onFocus) {
            props.onFocus()
        }

        setFocus(true)
    }



    return (
        <>
            <span className={`${styles.wrap} ${ props.class ? props.class : '' } ${ props.inline ? styles.inline : '' } ${ props.inline ? ( props.colCount == 1 ? styles.col1 : styles.col2 ) : '' } ${ focus ? styles.focus : '' } ${ props.valid === false ? styles.invalid : '' } ${ props.big ? styles.bigBox : '' } ${ props.multiline ? styles.multiline : '' }`}>
                {
                    props.icon &&
                    <span className={`${styles.iconWrap} ${ props.type === 'email' ? styles.email : '' }`}>
                        <img src={ icon } className={ styles.img }/>
                    </span>
                }
                {
                    (!props.big && !props.multiline) &&
                    <input
                        type={ props.type === 'password' ? 'password' : 'text' }
                        className={`${ styles.input }`}
                        placeholder={ props.placeholder ? props.placeholder : '' }
                        onChange={ (e) => props.changeFunc(e.target.value) }
                        onKeyDown={ props.onKeyDown ? props.onKeyDown : () => {} }
                        onFocus={ focusFunc }
                        onBlur={ () => setFocus(false) }
                        multi
                    />
                }
                {
                    (props.big || props.multiline) &&
                    <textarea
                        type={ props.type === 'password' ? 'password' : 'textarea' }
                        className={`${ styles.input } textarea`}
                        placeholder={ props.placeholder ? props.placeholder : '' }
                        // value={ props.value ? props.value : '' }
                        onChange={ handleChange }
                        onKeyDown={ props.onKeyDown ? props.onKeyDown : () => {} }
                        onFocus={ focusFunc }
                        onBlur={ () => setFocus(false) }
                    />
                }

            </span>
            {
                props.class &&
                <Tooltip className={ props.class }>
                    { props.tooltip }
                </Tooltip>
            }
        </>
    )
}