import React from 'react'
import { FC } from 'react'
import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { InputCenter } from '../../../funcs/Valid'
import DropDown from '../../DropDown'
import TextField from '../../TextField'
import styles from '../../../styles/components/slideUps/sendNoti.module.scss'
import { api } from '../../../funcs'
import Button from '../../Button'
import Modal from '../../Modal'

const VersionSelector = ({ setValue, setDirection }) => {

    const [advanced, setAdvanced] = useState(false)

    const items = [
        { label: '1.0+', value: '1' },
        { label: '2.0+', value: '2' },
    ]

    const directionList = [
        { label: 'Higher', value: '>=' },
        // { label: 'Lower', value: '<=' },
        { label: 'Equal', value: '==' },
    ]

    const switchMode = () => {
        setAdvanced(!advanced)
    }

    useEffect(() => {
        if (!advanced) {
            setDirection('>=')
            setValue('2')
        }
    }, [advanced])

    return (
        <div className={styles.versionWrap}>
            <span className={styles.left}>
                {
                    !advanced &&
                    <span className={styles.simpleWrap}>
                        <DropDown
                            items={items}
                            setValue={setValue}
                            icon='/imgs/icons/cog-filled.png'
                            placeholder="2.0"
                        // className={ styles.drop1 }
                        />
                    </span>
                }
                {
                    advanced &&
                    <span className={styles.advancedWrap}>
                        <span className={styles.txtWrap}>
                            <TextField
                                placeholder="Version Number"
                                icon='/imgs/icons/cog-filled.png'
                                // valid={ valid[field.title] }
                                // big={ field.big ? true : false }
                                changeFunc={setValue}
                                className={styles.versionNo}
                            // onFocus={ () => setCurrentField(field.title) }
                            />
                        </span>
                        <DropDown
                            items={directionList}
                            setValue={setDirection}
                            icon='/imgs/icons/cog-filled.png'
                            placeholder="Higher"
                        // className={ styles.drop1 }
                        />
                    </span>
                }
            </span>
            <span
                className={styles.right}
                onClick={switchMode}
            >
                <p>
                    {advanced ? 'Simple' : 'Advanced'}
                </p>
            </span>
        </div>
    )
}

const NotiPinger = ({ setOverlay }) => {

    const token = useRef(useSelector(state => state.user.token)).current
    const userID = useRef(useSelector(state => state.user.id)).current

    const [sending, setSending] = useState(false)
    const [sent, setSent] = useState(false)

    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [url, setURL] = useState('')
    const [desc, setDesc] = useState('')
    const [currentField, setCurrentField] = useState('')
    const [version, setVersion] = useState('')
    const [direction, setDirection] = useState('Higher')
    const IC = useRef(new InputCenter()).current

    const [valid, setValid] = useState({})
    const nameDict = {
        'Title': title,
        'URL': url,
        'Description': desc
    }

    const validate = (field) => {
        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [title, url, desc])

    const fields = [
        {
            title: 'Title',
            pattern: /^(.+)$/g,
            value: title,
            canBlank: false,
            set: setTitle,
            icon: ''
        },
        {
            title: 'URL',
            pattern: /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))$/g,
            value: url,
            canBlank: true,
            set: setURL,
            icon: '/imgs/icons/link-black.png',
        },
        {
            title: 'Description',
            pattern: /^(.|[\n\r\t])+$/g,
            value: desc,
            canBlank: false,
            set: setDesc,
            icon: '/imgs/icons/pencil.png',
            big: true
        },
    ]

    const sendNoti = async () => {

        let alertDesc = ''
        let res = await IC.allValid()

        if (res.valid && (category !== '')) {
            setSending(true)
            await api.post('news_notification', {
                "category": category,
                "jwttoken": token,
                "title": title,
                "url": url,
                "description": desc,
                "version": version,
                "direction": direction,
                "user_id": userID
            }).then((res) => {
                setSending(false)

                if (res.data.status == 'success' || res.status == 'success') {
                    alertDesc = 'Successful Notifications: '
                    alertDesc += res.data.sent_count
                }
                else {
                    alertDesc = 'failed to send notification.'
                    console.log(res.data)
                }
            })
        }
        else if (category == '') alertDesc = 'Please set a category!'
        else alertDesc = res.msg


        let alert = {
            desc: alertDesc,
            setFunc: () => { },
            alertBuffer: setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        localStorage.setItem('refreshPage', 'guides')
        setOverlay(0, alert)
    }

    useEffect(() => {
        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        setValid(tmpValid)
    }, [])

    const dropDown = {
        items: [
            { label: 'News', value: 'news_notification' },
            { label: 'Misc', value: 'misc_notification' },
            { label: 'Sneakers', value: 'sneaker_notification' },
            { label: 'Tickets', value: 'tickets_notification' },
            { label: 'Lifetime', value: 'lifetime_notification' },
            { label: 'Inactive', value: 'app_lead' },
            { label: 'Staff', value: 'devtest' }
        ]
    }

    return (
        <Modal setOverlay={setOverlay}>
            <DropDown
                items={dropDown.items}
                setValue={setCategory}
                icon={'/imgs/icons/file.png'}
                placeholder="Category"
            />
            {
                fields.map((field) => (
                    <TextField
                        placeholder={field.title}
                        icon={field.icon}
                        valid={valid[field.title]}
                        big={field.big ? true : false}
                        changeFunc={field.set}
                        onFocus={() => setCurrentField(field.title)}
                        tooltip={field.tooltip}
                    />
                ))
            }
            <VersionSelector setValue={setVersion} setDirection={setDirection} />
            <Button
                label='Send Notification'
                onClick={sendNoti}
            />
            {
                sending &&
                <div className={styles.loadingWrap}>
                    <img
                        src='/imgs/loading.svg'
                    />
                </div>
            }
        </Modal>
    )
}

export default NotiPinger