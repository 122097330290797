import Page from "../components/layouts/Page"

export default function Settings() {

    return (
        <Page
            title='Settings'
            current={ 4 }
        >

        </Page>
    )
}