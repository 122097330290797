import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rgx } from '../../../constants'
import { CrudControl } from '../../../lib/crud'
import { Modal } from '../..'
import { actions } from '../../../redux/actions'

export default function NewBuyPinger({ load, setOverlay }) {

    const dispatch = useDispatch()
    
    const token = useRef( useSelector( state => state.user.token ) ).current
    const userID = useRef( useSelector( state => state.user.id ) ).current

    // Field data
    
    const [ category, setCategory ] = useState('')
    const [ releaseType, setReleaseType ] = useState('')
    const [ name, setName ] = useState('')
    const [ url, setURL ] = useState('')
    const [ retail, setRetail ] = useState('')
    const [ resell, setResell ] = useState('')
    const [ releaseDate, setReleaseDate ] = useState('')
    const [ releaseTime, setReleaseTime ] = useState('')
    const [ startReleaseTime, setStartReleaseTime ] = useState('')
    const [ endReleaseTime, setEndReleaseTime ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ discordImg, setDiscordImg ] = useState('')
    const [ desc, setDesc ] = useState('')
    const [ sellingTime, setSellingTime ] = useState('')
    const [ returns, setReturns ] = useState('')
    const [ shipping, setShipping ] = useState('')
    const [ soldListing, setSoldListing ] = useState('')
    const [ whereToSell, setWhereToSell ] = useState('')
    const [ categorySet, setCategorySet ] = useState(false)
    const [ releaseTypeSet, setReleaseTypeSet ] = useState(false)

    
    const [alert, setAlert] = useState(false) 

    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const categoryItems = [
        { label: 'Main', value: 'main' },
        { label: 'Lifetime', value: 'lifetime' },
        { label: 'Flip Test', value: 'fliptest' },
        { label: 'Developer Testing', value: 'devtest' },
    ]
    
    const releaseTypeItems = [
        { label: 'Default', value: 'Default' },
        { label: 'Range', value: 'Range' },
        { label: 'Out Now', value: 'Out Now' },
        { label: 'Coming Soon', value: 'Coming Soon' },
        { label: 'Previously Released', value: 'Previously Released' },
        { label: 'Expired', value: 'Expired' },
    ]

    const [ activeField, setActiveField ] = useState([false, false, false, false])

    // Activates and disbles relevant fields when the release type is updated
    const setDateFields = () => {
        if (releaseType !== '' ) {
            setReleaseTypeSet(true)
        }

        /*
        1 - Release Date
        2 - Release Time
        3 - Start time
        4 - End time
        */
       
        switch (releaseType) {
            case 'Out Now':
                setActiveField([false, false, false, false])
                break;
            case 'Default':
                setActiveField([true, true, false, false])
                break;
            case 'Range':
                setActiveField([true, false, true, true])
                break;
            case 'Coming Soon':
                setActiveField([false, false, false, false])
                break;
            case 'Previously Released':
                setActiveField([false, false, false, false])
        }
    }

    useEffect(() => {
        setDateFields()
    }, [ releaseType ])
    
    const fields = [
        {
            type: 'dropdown',
            title: "category",
            items: categoryItems,
            placeholder: 'Category',
            pattern: rgx.article,
            canBlank: false,
            value: category,
            set: setCategory
        },
        {
            type: 'dropdown',
            title: "release type",
            items: releaseTypeItems,
            placeholder: 'Release Type',
            pattern: rgx.article,
            canBlank: false,
            value: releaseType,
            set: setReleaseType
        },
        {
            icon: '/imgs/icons/person.png',
            title: 'Product Name',
            pattern: /^(.+)$/g,
            value: name,
            canBlank: false,
            set: setName
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Product URL',
            pattern: rgx.url,
            value: url,
            canBlank: true,
            set: setURL
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Retail',
            pattern: rgx.tbcOrPrice,
            value: retail,
            canBlank: false,
            className: 'retail',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setRetail
        },
        {
            icon: '/imgs/icons/price-tag-black.png',
            title: 'Resell',
            pattern: rgx.tbcOrPrice,
            value: resell,
            canBlank: false,
            className: 'resell',
            tooltip: 'e.g. 350 or a range like 350 - 400',
            set: setResell
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Date',
            pattern: /^([\d]{2,2}\.[\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseDate,
            canBlank: !activeField[0],
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 1,
            className: 'release-date-1',
            tooltip: 'dd.mm.yy',
            set: setReleaseDate,
            visible: activeField[0]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Release Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: releaseTime,
            canBlank: !activeField[1],
            dateTime: true,
            dateCount: 1,
            inline: true,
            colCount: 2,
            className: 'release-time-1',
            tooltip: 'hh.mm',
            set: setReleaseTime,
            visible: activeField[1]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Start Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: startReleaseTime,
            canBlank: !activeField[2],
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 1,
            className: 'start-time',
            tooltip: 'hh.mm',
            set: setStartReleaseTime,
            visible: activeField[2]
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'End Time',
            pattern: /^([\d]{2,2}\.[\d]{2,2})$/g,
            value: endReleaseTime,
            canBlank: !activeField[3],
            dateTime: true,
            dateCount: 2,
            inline: true,
            colCount: 2,
            className: 'end-time',
            tooltip: 'hh.mm',
            set: setEndReleaseTime,
            visible: activeField[3]
        },
        {
            icon: '/imgs/icons/img.png',
            title: 'Image URL',
            pattern: rgx.url,
            value: img,
            canBlank: false,
            set: setImgBuffer
        }, 
        {
            icon: '/imgs/icons/discord.png',
            title: 'Discord Image (URL)',
            pattern: rgx.url,
            value: discordImg,
            canBlank: true,
            set: setDiscordImg
        }, 
        {
            icon: '/imgs/icons/pencil.png',
            title: 'Description',
            pattern: /^(.|[\n\r\t])+$/g,
            value: desc,
            canBlank: false,
            set: setDesc,
            multiline: true
        },
        {
            icon: '/imgs/icons/clock.png',
            title: 'Average Selling Time',
            pattern: /^(.+)$/g,
            value: sellingTime,
            canBlank: false,
            set: setSellingTime
        },
        {
            icon: '/imgs/icons/returns.png',
            title: 'Returns',
            pattern: /^(.+)$/g,
            value: returns,
            canBlank: false,
            set: setReturns
        },
        {
            icon: '/imgs/icons/lorry.png',
            title: 'Shipping Cost (£)',
            pattern: /^(\d+)$/g,
            value: shipping,
            canBlank: false,
            set: setShipping
        },
        {
            icon: '/imgs/icons/link-black.png',
            title: 'Ebay Sold Listing URL',
            pattern: /^((tbc|TBC)|(https?:\/\/(www\.)?ebay\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)))$/g,
            value: soldListing,
            canBlank: false,
            set: setSoldListing
        },
        {
            icon: '/imgs/icons/store.png',
            title: 'Where To Sell',
            pattern: /^(.+)$/g,
            value: whereToSell,
            canBlank: true,
            set: setWhereToSell
        }
    ]


    // CRUD

    const crudControl = useRef( new CrudControl(token) ).current


    const newBuyProps = {
        "product_name": name,
        "url": url,
        "price": retail,
        "resell_value": resell,
        "release_time": ['Live', 'Random'].includes(releaseType) ? '' : ( releaseType == 'Default' ? releaseDate + ' ' + releaseTime : releaseDate + ' ' + startReleaseTime + ' ' + endReleaseTime),
        "release_type": releaseType,
        "image_url":  img,
        "discord_image": discordImg,
        "return_policy": returns,
        "description": desc,
        "where_to_sell": whereToSell,
        "ebay_listings": soldListing,
        "shipping_cost":  shipping,
        "selling_time": sellingTime,
        "category": category,
        "user_id": userID
    }

    const setup = () => {
        dispatch(actions.setRefreshPage('new buy'))
    }

    const triggerRefresh = () => {
        dispatch(actions.setRefresh(true))
    }
    
    useEffect(() => {
        setup()
    }, [])

    return (
        <Modal
            inputs={ fields }
            inputsPerPage={ 4 }
            title={ "Create New Buy" }
            set={ (l) => {} }
            func={ async () => await crudControl.createNewBuy(newBuyProps, triggerRefresh ) }
            setAlert={ setAlert }
            setOverlay={ setOverlay }
        />
    )
}