import { api } from "../../funcs"

const read = async (token, endpoint, callBack = async() => {}) => {

    let success = true
    let msg = ''
    let data = []

    const queryEndpoint = async () => {

        await api.get2(endpoint, { jwttoken: token }).then((res) => {
            data = res.data
        }).catch((e) => {
            success = false
            console.error(e)
        })
    }

    await queryEndpoint()
    await callBack()

    return {
        success,
        msg,
        data
    }
}

// Restocks

export const restock = (token, callBack = async() => {}) => read(token, 'notificationhistory', callBack)

// New Buys

export const newBuy = (token, callBack = async() => {}) => read(token, 'newbuy_history', callBack)

// Guides

export const guide = (token, callBack = async() => {}) => read(token, 'guide_news_history', callBack)

// Notis

export const noti = (token, callBack = async() => {}) => read(token, 'noti_schedule', callBack)

// Products

export const product = (token, callBack = async() => {}) => read(token, 'product_list', callBack)