import axios from 'axios'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Page from '../components/layouts/Page'
import Modal from '../components/Modal'
import { pinger } from '../components/slideUps/pinger'
import UpdateNoti from '../components/slideUps/updateNoti'
import StatCount from '../components/StatCount'
import Table from '../components/Table'
import { rgx } from '../constants'
import { isMobile, parseTitle, refreshListener } from '../funcs'
import { api } from '../funcs/apiCall'
import { crud, CrudControl } from '../lib/crud'
import datetime from '../lib/time'
import { actions } from '../redux/actions'
import styles from '../styles/appfeed.module.scss'


export default function Notifications({ setOverlay, blur }) {

    const dispatch = useDispatch()

    const token = useRef( useSelector( state => state.user.token ) ).current
    const [alert, setAlert] = useState(false)
    const [slide, setSlide] = useState(1)
    const [item, setItem] = useState()
    const [searchStr, setSearchStr] = useState('')

    const refresh = useSelector( state => state.refresh.run )
    const refreshPage = useSelector( state => state.refresh.page )
    
    // Table data
    
    const [notis, setNotis] = useState([])
    const [headers, setHeaders] = useState([])

    // Field data

    const [ category, setCategory ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ content, setContent ] = useState('')
    const [ url, setURL ] = useState('')
    const [ version, setVersion ] = useState('')
    const [ date, setDate ] = useState('')
    const [ time, setTime ] = useState('')

    const fields = [
        {
            title: 'Title',
            pattern: rgx.name,
            value: title,
            canBlank: false,
            set: setTitle,
            icon: '/imgs/icons/img.png'
        },
        {
            title: 'URL',
            pattern: rgx.url,
            value: url,
            canBlank: true,
            set: setURL,
            icon: '/imgs/icons/link-black.png',
            className: 'url',
            tooltip: 'https://website.com'
        },
        {
            title: 'Date',
            pattern: rgx.date,
            value: date,
            canBlank: false,
            inline: true,
            colCount: 1,
            set: setDate,
            icon: '/imgs/icons/clock.png',
            tooltip: 'dd.mm.yy',
            className: 'date'
        },
        {
            title: 'Time',
            pattern: rgx.time,
            value: time,
            canBlank: false,
            inline: true,
            colCount: 2,
            set: setTime,
            icon: '/imgs/icons/clock.png',
            tooltip: 'hh.mm',
            className: 'time'
        },
        {
            title: 'Content',
            pattern: rgx.article,
            value: content,
            canBlank: false,
            multiline: true,
            set: setContent,
            big: true,
            icon: '/imgs/icons/book-black.png'
        },
    ]

    // CRUD 

    const crudControl = useRef( new CrudControl(token) ).current

    const loadNotis = async () => {

        await api.get2('noti_schedule', { jwttoken: token }).then((res) => {

            let headData = []
    
            if (!isMobile()) {
                headData = [
                    { title: 'Title', field: 'title'},
                    // { title: 'Category',  field: 'category'},
                    { title: 'Date', field: 'date'},
                    { title: 'Time', field: 'time'},
                    { title: 'Uploaded', field: 'uploaded'},
                    { title: 'Publisher', field: 'publisher'},
                ]
            }
            else {
                headData = [
                    { title: 'Title', field: 'title'},
                    // { title: 'Category',  field: 'category'},
                    { title: 'Date', field: 'date'},
                ]
            }

            let parsed = res.data.map((record) => {

                let created = datetime.getStandard(record.creation_time)
                let schedule = datetime.getStandard(record.time)
                
                return {
                    id: record.id,
                    image: record.image_url,
                    // category: record.category,
                    date: schedule.date,
                    time: schedule.time,
                    title: record.title,
                    uploaded: created.date,
                    publisher: record.creator ? record.creator : 'Null',
                    url: record.ad_info,
                    category: record.noti_type
                }
            })

            setHeaders(headData)
            setNotis(parsed)
        }).catch((e) => {
            console.log('ERROR: ', e)
            setNotis([])
        })
    }

    const notiProps = {
        'noti_type': category,
        'title': title,
        'content': content,
        'ad_info': url,
        'version': version,
        'date': date,
        'time': time
    }

    const crudFunc = {
        create: <pinger.scheduledNoti setOverlay={ setOverlay } load={ loadNotis }/>,
        read: (record) => window.open(record.url, '_blank'),
        update: (record) =>  {
            setOverlay(2, <UpdateNoti setOverlay={ setOverlay } item={ notis.filter((item) => item.id == record.id)[0] }/>)
        },
        delete: (itemList) => crudControl.deleteNoti(itemList, loadNotis),
        save: () => {}
    }

    const refreshListener = (page, func, interval = 8000) => {

        setInterval(() => {
            if (refreshPage == page) {
                func()
            }
        }, interval)
    }

    useEffect(() => {
        loadNotis()
    }, [])

    useEffect(() => {
        loadNotis()

        if ((refreshPage == 'noti schedule') && refresh) {
            loadNotis()
            dispatch(actions.setRefresh(false))
        }
    }, [ refresh ])

    return (
        <Page
            title='Notification Center'
            current={3}
            setOverlay={setOverlay}
            blur={blur}
            setSearchStr={ setSearchStr }
        >
            {
                alert !== false &&
                <Modal
                    text={ alert }
                    set={ setAlert }
                />
            }
            <Table
                headers={ headers }
                data={ notis }
                crudFunc={ crudFunc }
                filterField="publisher"
                btnText="Notification"
                setAlert={ setAlert }
                searchStr={ searchStr }
                setOverlay={ setOverlay }
            />
        </Page>
    )
}