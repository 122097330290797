import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux"
import { api } from '../../../funcs/apiCall'
import { InputCenter } from '../../../funcs/Valid'
import { rgx } from "../../../constants"
import Button from "../../Button"
import TextField from "../../TextField"
import styles from '../../../styles/components/slideUps/updateBanner.module.scss'
import Modal from "../../Modal"

const BannerPinger = ({ setOverlay }) => {

    const token = useRef( useSelector( state => state.user.token ) ).current
    const [ img, setImg ] = useState('/imgs/icons/img.svg')
    const [ url, setURL ] = useState('')
    const [ valid, setValid ] = useState({})
    const [ currentField, setCurrentField ] = useState('')
    const IC = useRef( new InputCenter() ).current 

    const nameDict = {
        'Image (URL)': img,
        'URL': url
    }

    const validate = (field) => {
        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }

    const fields = [
        {
            title: 'Image (URL)',
            pattern: rgx.url,
            value: img,
            canBlank: false,
            set: setImg,
            icon: '/imgs/icons/img.png'
        },
        {
            title: 'URL',
            pattern: rgx.url,
            value: url,
            canBlank: false,
            set: setURL,
            icon: '/imgs/icons/link-black.png'
        }
    ]

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ img, url ])

    useEffect(() => {
        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        setValid(tmpValid)
    }, [])

    

    const updateBanner = async () => {

        let alertDesc = 'ERROR'
        let res = await IC.allValid()

        console.log(res)

        if (res.valid) {
            try {
                await api.post('update_banner', {
                    "jwttoken": token,
                    "img": img,
                    "url": url
                }).then((res) => {
                    console.log(res.data)
                    if(res.data.status == "success"){
                        alertDesc = "The banner has been updated! (Note: banner changes may take up to 5 minutes to reflect in the app.)"
                    }
                    else {
                        alertDesc = res.data.error
                    }
                })
            }
            catch (e) {
                alertDesc = 'Failed to update banner.'
                console.error(e)
            }
        }
        else  {
            alertDesc = res.msg
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => {},
            alertBuffer: setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: false
                },
            ]
        }

        setOverlay(0, alert)
    }

    return (
        <Modal setOverlay={ setOverlay }>
            <div className={ styles.imgBigWrap }>
                <span className={ styles.imgWrap }>
                    <img src={ img }/>
                </span>
            </div>
            {
                fields.map((field) => (
                    <TextField
                        placeholder={ field.title }
                        icon={ field.icon }
                        valid={ valid[field.title] }
                        big={ field.big ? true : false }
                        changeFunc={ field.set }
                        onFocus={ () => setCurrentField(field.title) }
                    />
                ))
            }
            <Button
                label="Update Banner"
                onClick={ updateBanner }
            />
        </Modal>
    )
}

export default BannerPinger