import { useState, useEffect, useRef } from 'react'
import styles from '../styles/components/textfield2.module.scss'

const createID = (name) => {

    let parsedName = name.replaceAll(' ', '-').replaceAll(/([0-9]|[.!@£$%^&*(){}":|><])/g, '')
    let no = Math.random(5) + ""

    no = no.slice(2,5)

    if (name = '') {
        return 'a' + no
    }

    return parsedName + no 
} 

export default function TextField2(props) {

    const [ focus, setFocus ] = useState(false)
    const [ icon, setIcon ] = useState()
    const icons = {
        email: '/imgs/icons/email.png',
        person: '/imgs/icons/person.png',
        padlock: '/imgs/icons/padlock.png'
    }
    const id = useRef(props.label ? createID(props.label) : '').current
    const placeholder = useRef(props.placeholder).current
    const [ valueSet, setValueSet ] = useState(false)
    // const [ iconStyle, setIconStyle ] = useState(styles.iconPerson)
    const [ isVisible, setVisible ] = useState(true)
    // const [ passIcon, setPassIcon ] = useState(faEye)

    // const toggleVisible = () => {
    //     setVisible(!isVisible)
    //     setPassIcon(isVisible ? faEye : faEyeSlash)
    // }

    const handleChange = (e) => {
        if (props.changeFunc) {
            props.changeFunc(e.target.value)
        }
    }

    useEffect(() => {
        
        switch(props.type) {
            case 'name':
                setIcon(icons.person)
                // setIconStyle(styles.iconPerson)
                break
            case 'email':
                setIcon(icons.email)
                // setIconStyle(styles.iconEmail)
                break
            case 'password':
                setVisible(false)
                setIcon(icons.padlock)
                // setIconStyle(styles.iconPadlock)
                break
            default:
                if (props.icon) {
                    setIcon(props.icon)
                }
                else {
                    setIcon(icons.person)
                }
                break
        }
    }, [])

    useEffect(() => {

            try {
                let inputField = document.getElementById(id)

                inputField.value = props.placeholder
                
                setValueSet(true)
            }
            catch (e) {
            }
    })

    const focusFunc = () => {
        if (props.onFocus) {
            props.onFocus()
        }

        setFocus(true)
    }

    return (
        <>
            <span className={`${styles.wrap} ${ props.class ? props.class : '' }  ${ focus ? styles.focus : '' } ${ props.valid === false ? styles.invalid : '' } ${ props.big ? styles.bigBox : '' }`}>
                {/* <span className={`${styles.iconWrap} ${ props.type === 'email' ? styles.email : '' }`}>
                    <img src={ icon } className={ styles.img }/>
                </span> */}
                <p className={ styles.label }>{ props.label }</p>
                { 
                    !props.big &&
                    <input
                        id={ id } 
                        type={ props.type ==='password' ? 'password' : 'text' }
                        className={`${ styles.input } ${ props.valid === false ? styles.invalid : '' }`}
                        placeholder={ props.placeholder ? props.placeholder : '' }
                        onChange={ (e) => props.changeFunc(e.target.value) }
                        onKeyDown={ props.onKeyDown ? props.onKeyDown : () => {} }
                        onFocus={ focusFunc }
                        onBlur={ () => setFocus(false) }
                        multi
                    />
                }
                {
                    props.big &&
                    <textarea
                        id={ id } 
                        type={ props.type ==='password' ? 'password' : 'textarea' }
                        className={`${ styles.input } ${ props.valid === false ? styles.invalid : '' } ${ styles.textArea } textarea`}
                        placeholder={ props.placeholder ? props.placeholder : '' }
                        // value={ props.value ? props.value : '' }
                        onChange={ handleChange }
                        onKeyDown={ props.onKeyDown ? props.onKeyDown : () => {} }
                        onFocus={ focusFunc }
                        onBlur={ () => setFocus(false) }
                    />
                }

            </span>
        </>
    )
}