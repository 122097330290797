export { Input } from './Valid'
export { api } from './apiCall'

export function isMobile() {
    return window.innerWidth <= 768
}

export function shouldRefresh(target) {
    let curPage = window.location.pathname.replace('/', '').toLowerCase()
    console.log(target.toLowerCase())
    console.log(curPage)
    if (target.toLowerCase() == curPage) {
        window.location.reload(false)
    }
}

export function refreshListener(page, func) {
    setInterval(() => {
            
        let refreshPage = localStorage.getItem('refreshPage')
        if (refreshPage == page.toLowerCase()) {
            localStorage.setItem('refreshPage', '')
            func()
        }
    }, 4000)
}

export function parseTitle(title) {

    return title.length > 26 ? title.slice(0, 26).trim() + '...' : title
}