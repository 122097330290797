import { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { api } from '../funcs/apiCall'
import styles from '../styles/components/nav.module.scss'
import { pinger } from './slideUps/pinger'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../redux/actions'
import ScheduleNoti from './slideUps/scheduleNoti'
import { helpers } from '../constants'

const MobileHeader = ({ menu, setMenu }) => {

    const toggleMenu = () => {
        setMenu( !menu )
    }

    return (
        <div className={ styles.mobileHeader }>
            <div className={`${ styles.hamWrap } ${ menu ? styles.activeHamWrap : '' }`}
                onClick={ toggleMenu }
            >
                <span className={ styles.hamSlice }></span>
                <span className={ styles.hamSlice }></span>
            </div>
            <span className={ styles.logoWrap }>
                <img src='/imgs/icons/acronym-logo.png' className={ styles.logo } />
            </span>
        </div>
    )
}

const AddMenu = ({ setSlideUp, setOpen, open }) => {

    const setSlide = (id) => {

        setSlideUp(id)
        setOpen(false)
    }

    const menuItems = useRef([
        {
            title: 'Restock',
            onClick: () => setSlide(1)
        },
        {
            title: 'New Product',
            onClick: () => setSlide(2)
        },
        {
            title: 'New Buy',
            onClick: () => setSlide(3)
        },
        {
            title: 'Add Guide',
            onClick: () => setSlide(4)
        },
        {
            title: 'Send Notification',
            onClick: () => setSlide(5)
        },
        {
            title: 'Update Banner',
            onClick: () => setSlideUp(6)
        },
        {
            title: 'Schedule Noti',
            onClick: () => setSlideUp(7)
        }
    ]).current

    return (
        <ul className={`${ styles.addMenu } ${ open ? styles.addMenuOpen : '' } addMenu`}>
            <h1>Add Menu</h1>
            {
                menuItems.map((item, index) => (
                    <li
                        onClick={item.onClick}
                        key={index}
                        className='addMenuBtn'
                    >
                        {item.title}
                    </li>
                ))
            }
        </ul>
    )
}

const NavItem = ({ data, onClick, token, current }) => {

    const [access, setAccess] = useState(false)

    const checkAuth = async () => {

        await api.get2('checkauth', { "jwttoken": token }).then((res) => {
            console.log(res.data.analytics)
            if (res.status == 'success') {
                setAccess(res.data.analytics)
            }
            else {
                setAccess(false)
            }
        })
    }

    useEffect(() => {

        if (data.title === 'Analytics') {
            checkAuth()
        }
        else {
            setAccess(true)
        }


    }, [])

    return (
        <span
            className={`${styles.item} ${data.class ? data.class : ''} ${data.allClass ? data.allClass : ''} ${access ? '' : styles.stealth} ${current == data.slug.toLowerCase() ? styles.current : ''}`}
            onClick={data.onClick ? data.onClick : onClick}
        >
            {
                data.title &&
                <p className={`${styles.title}  ${data.current ? styles.dark : styles.light} ${data.allClass ? data.allClass : ''}`}>{data.title}</p>
            }
            {
                data.class == styles.addMenuBtn &&
                <p
                    onClick={data.onClick ? data.onClick : onClick}
                    className='addMenuBtn'
                >+</p>
            }
            <span className={`${styles.selectSlice}`}>

            </span>
        </span>
    )
}

export default function NavBar({ current, setOverlay }) {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const token = useRef( useSelector( state => state.user.token ) ).current
    const [addMenuOpen, setAddMenuOpen] = useState(false)
    const [ mainMenuOpen, setMainMenuOpen ] = useState(false)
    const [ currentPage, setCurrentPage ] = useState('lool')

    const setSlideUp = (id) => {

        switch (id) {
            case 1:
                setOverlay(3, <pinger.restock setOverlay={ setOverlay }/>)
                break
            case 2:
                setOverlay(3, <pinger.product setOverlay={setOverlay} />)
                break
            case 3:
                setOverlay(3, <pinger.newBuy setOverlay={setOverlay} />)
                break
            case 4:
                setOverlay(3, <pinger.guide setOverlay={setOverlay} />)
                break
            case 5:
                setOverlay(3, <pinger.noti setOverlay={setOverlay} />)
                break;
            case 6:
                setOverlay(3, <pinger.banner setOverlay={setOverlay} />)
                break
            case 7:
                setOverlay(3, <pinger.scheduledNoti setOverlay={setOverlay} />)
                break;
            default:
                break
        }
    }

    const page = document.getElementsByTagName('body')[0]
    const addMenu = document.getElementsByClassName('addMenu')[0]

    useEffect(() => {

        page.addEventListener('click', e => {

            const classList = e.target.classList
            const classStr = classList.toString()

            if (!classStr.includes('addMenuBtn')) {
                setAddMenuOpen(false)
            }
        })

        let urlChunks = window.location.href.toLocaleLowerCase().split('/')
        setCurrentPage(urlChunks[urlChunks.length - 1])

    }, [])

    let navItemsArray = [
        {
            class: `${styles.newBtn} addMenuBtn`,
            onClick: () => setAddMenuOpen(true),
            class: styles.addMenuBtn,
            allClass: 'addMenuBtn',
            slug: ''
        },
        {
            title: 'Restocks',
            slug: 'Restocks',
        },
        {
            title: 'New Buys',
            slug: 'New-Buys',
        },
        {
            title: 'Products',
            slug: 'Products'
        },
        {
            title: 'Guides',
            slug: 'Guides',
        },
        {
            title: 'Noti Center',
            slug: 'Notifications',
        },
        {
            title: 'Notif Stats',
            slug: 'Notif_Stats',
        }
    ]

    const userID = useRef( useSelector( state => state.user.id ) ).current
console.warn(userID);
    // add analytic pages to user with all ids except specific id
    if (helpers.analyticNonAllowedUserIds.indexOf(userID) == -1) {
        navItemsArray.push({
            title: 'Analytics',
            slug: 'Analytics',
            imgClass: styles.analytics,
        });        
    }
    

    // add analytic seller pages to specific user ids
    const allowedUserIds = helpers.analyticSellerUserIds;
    if (allowedUserIds.indexOf(userID) > -1) {
        navItemsArray.push({
            title: 'Analytics (Seller)',
            slug: 'Analytics_seller',
            imgClass: styles.analytics
        });
    }
    
    const navItems = useRef(navItemsArray).current

    const logOut = () => {
        dispatch(actions.setEmail(''))
        dispatch(actions.setToken(''))
        navigate('/Login')
    }

    return (
        <>
            <AddMenu
                setSlideUp={setSlideUp}
                setOpen={setAddMenuOpen}
                open={ addMenuOpen }
            />
            <div className={`${ styles.wrap } ${ mainMenuOpen ? styles.menuAcco : '' }`}>
                <div className={`${ styles.top }`}>
                    <span>
                        <img src='/imgs/icons/acronym-logo.png' className={styles.logo} />
                    </span>
                </div>
                <div className={`${ styles.bot }`}>
                    {
                        navItems.map((item, index) =>
                            <NavItem
                                data={item}
                                onClick={() => navigate(`/${item.slug}`)}
                                key={index}
                                token={token}
                                current={currentPage}
                            />
                        )
                    }
                </div>
                <div className={ styles.foot }>
                    <button
                        className={ styles.logOutBtn }
                        onClick={ logOut }
                    >
                        <img
                            src='/imgs/icons/exit.png'
                            className={ styles.img }
                        />
                    </button>
                </div>
            </div>

            <MobileHeader setMenu={ setMainMenuOpen } menu={ mainMenuOpen }/>
        </>
    )
}