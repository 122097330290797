import {
    SET_NAME,
    SET_EMAIL,
    SET_TOKEN,
    SET_ID,
    SET_REFRESH,
    SET_REFRESH_PAGE,
    SET_OVERLAY_FUNC
} from './types'

const action = (type, payload) => {

    if (payload !== undefined) {
        return {
            type,
            payload
        }
    }
    else {
        return {
            payload
        }
    }
}

const setName = (payload) => (action(SET_NAME, payload))
const setEmail = (payload) => (action(SET_EMAIL, payload))
const setToken = (payload) => (action(SET_TOKEN, payload))
const setID = (payload) => (action(SET_ID, payload))
const setRefresh = (payload) => (action(SET_REFRESH, payload))
const setRefreshPage = (payload) => (action(SET_REFRESH_PAGE, payload))
const setOverlayFunc = (payload) => (action(SET_OVERLAY_FUNC), payload)

export const actions = {
    setName,
    setEmail,
    setToken,
    setID,
    setRefresh,
    setRefreshPage,
    setOverlayFunc
}