import { useRef, useEffect, useState } from 'react'
import styles from '../styles/components/slideUp.module.scss'

export default function SlideUp({ children, buffer , refreshPage }) {

    const close = (run) => {

        if (run) {
            buffer(1, null)
        }
    }

    const handleOverlayClick = (e) => {
        // e.preventDefault()

        if (e.target === e.currentTarget) {

            let alert = {
                desc: 'Are you sure you want to close this screen?',
                setFunc: close,
                alertBuffer: buffer,
                btns: [
                    {
                        label: 'No',
                        returnValue: false
                    },
                    {
                        label: 'Yes',
                        returnValue: true
                    }
                ]
            }
            
            buffer(0, alert)
        }
    }

    return (
        <div
            className={`bgOverlay ${ styles.container }`}
            onClick={ handleOverlayClick }
        >
            <div className={ styles.wrap }>
                {
                    children
                }
            </div>
        </div>
    )
}