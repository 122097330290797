import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { api } from '../../funcs/apiCall'
import Button from '../Button'
import TextField from '../TextField'
import styles from '../../styles/components/slideUps/updateRestock.module.scss'
import DropDown from '../DropDown'
import { InputCenter } from '../../funcs/Valid'
import { shouldRefresh } from '../../funcs'
import TextField2 from '../TextField2'
import { rgx } from '../../constants'
import TextTitle from '../TextTitle'
import Modal from '../Modal'


export default function UpdateRestock(props) {
    
    const token = useRef( useSelector( state => state.user.token ) ).current

    const [ name, setName ] = useState('')
    const [ img, setImg ] = useState('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
    const [ resell, setResell ] = useState('')
    const [ ebaySoldLink, setEbaySoldLink ] = useState('')
    const [ id, setID ] = useState('')
    const [ alert, setAlert ] = useState(false)

    const [ currentField, setCurrentField ] = useState('')
    const [ valid, setValid ] = useState({})

    const nameDict = {
        'Resell Value': resell,
        'Name': name,
        'Ebay sold link': ebaySoldLink
    }

    const IC = useRef( new InputCenter() ).current

    const validate = (field) => {

        let tmp = {
            ...valid
        }

        tmp[field] = IC.isValid(field).valid

        setValid({
            ...tmp
        })
    }

    const loadProducts = async () => {
        await api.get2('product_list', { 'jwttoken': token }).then((res) => {
            let item = res.data.products.find(x => x["id"] === props.item.id)

            console.log(item)

            setID(item.id)
            setName(item.product_name)
            setImg("https://dk1adr1q40rtx.cloudfront.net/appmedia/" + item.image_name)
            setResell(item.resell_value)
            setEbaySoldLink(item.ebay_sold_link)
        })
    }

    useEffect(() => {
        IC.setValue(currentField, nameDict[currentField])
        validate(currentField)
    }, [ name, resell, ebaySoldLink ])

    const setImgBuffer = (value) => {
        
        if (value === '' || value === ' ') {
            setImg('https://cdn.discordapp.com/attachments/871750276426649640/961933446496813056/Stockimage-01.png')
        }
        else {
            setImg(value)
        }
    }

    const fields = useRef([
        {
            title: 'Name',
            pattern: rgx.name,
            value: name,
            canBlank: false,
            set: setName
        },
        {
            title: 'Resell Value',
            pattern: rgx.price,
            value: resell,
            canBlank: true,
            set: setResell
        },
        {
            title: 'Ebay sold link',
            pattern: rgx.url,
            value: ebaySoldLink,
            canBlank: true,
            set: setEbaySoldLink
        }
    ]).current

    const updateNoti = async () => {

        let alertDesc = ''
        let res = await IC.allValid()

        if (res.valid) {
            await api.post('update_product', {
                "product_id": id,
                "product_name": name,
                "resell_value": resell,
                "ebay_sold_link": ebaySoldLink,
                "jwttoken": token
            }).then((res) => {
                if(res.data.status === "success"){
                    alertDesc = "Product updated!"
                }
                else {
                    alertDesc = "Update failed."
                }

                console.log(res.data)
            })
        }
        else {
            alertDesc = res.msg
        }


        let alert = {
            desc: alertDesc,
            setFunc: () => props.setOverlay(0, null),
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'OK',
                    returnValue: true
                },
            ]
        }

        localStorage.setItem('refreshPage', 'restocks')
        props.setOverlay(0, alert)
    }

    const confirmAlert = () => {
        let alert = {
            desc: 'Are you sure you want to make these changes?',
            setFunc: updateNoti,
            alertBuffer: props.setOverlay,
            btns: [
                {
                    label: 'No',
                    returnValue: false
                },
                {
                    label: 'Yes',
                    returnValue: true
                },
            ],
        }

        props.setOverlay(0, alert)
    }

    useEffect(() => {

        let tmpValid = {}

        fields.map((field) => {
            IC.addInput(field)
            tmpValid[field.title] = true
        })

        fields.map((field) => {
            IC.setValue(field.title, nameDict[field.title])
        })

        setValid(tmpValid)
    }, [ resell ])

    useEffect(() => {
        loadProducts()
    }, [])

    return (
        <div className={ styles.wrap }>
            {
                alert !== false &&
                <Modal
                    text={ alert.text }
                    btns={ alert.btns }
                />
            }
            <div className={ styles.topWrap }>
                <h1
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        opacity: '.9'
                    }}
                >Edit Product</h1>
                <div className={ styles.imgOuterWrap }>
                    <div className={ styles.imgWrap }>
                        <img src={ img } className/>
                    </div>
                </div>
                <TextTitle
                    title={ name }
                    setFunc={ setName }
                />
            </div>
            <div className={ styles.bottomWrap }>
                <div>
                    <TextField2
                        changeFunc={ fields[0].set }
                        valid={ valid[fields[0].title] }
                        label={ fields[0].title }
                        placeholder={ name }
                        unit='£'
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[0].title) }
                    />
                    <TextField2
                        changeFunc={ fields[1].set }
                        valid={ valid[fields[1].title] }
                        label={ fields[1].title }
                        placeholder={ resell }
                        unit='£'
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[1].title) }
                    />
                     <TextField2
                        changeFunc={ fields[2].set }
                        valid={ valid[fields[2].title] }
                        label={ fields[2].title }
                        placeholder={ ebaySoldLink }
                        // type="custom"
                        // icon={ field.icon }
                        onFocus={ () => setCurrentField(fields[2].title) }
                    />
                </div>
                <Button
                    label="Update"
                    onClick={ confirmAlert }
                    class={ styles.sendBtn }
                />
            </div>
        </div>
    )
}